import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {},
	reducers: {
		updateDashBoard: (state, action) => {
			state.dashboard = action.payload;
		},
	},
});

export const getDashboardState = (state) => state.dashboard;
export const getDashboardChartById = (id) => (state) =>
	state?.dashboard?.dashboard?.data?.[id] ?? { data: "no data" };

export const getRecipeDataById = (id) => (state) =>
	state?.dashboard?.dashboard?.data?.[id]?.recipedata?.optimizerData
		?.recipe_data ?? { data: "no data" };

export const { updateDashBoard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
