import * as React from "react";

import { ButtonGroup } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

/**
 * TODO
 * 	- add [apply to niagara] btn, display if the recipe is in live and display pop up when clicked
 * 	- recipe confirmation pop up
 */

export function getItemsName(_id, data = {}) {
	if (_id == "*") return "ALL";
	const { name = "" } = data;
	return name;
}

export default function ResourcePermissionTable({
	data,
	onDelete,
	hideDelete = false,
}) {
	function getActionButtons(row) {
		return (
			<ButtonGroup
				variant='outlined'
				aria-label='recipe action buttons'
				size='small'>
				<DeleteIcon
					className='delete-icon'
					onClick={() => {
						onDelete(row);
					}}
				/>
			</ButtonGroup>
		);
	}

	const style = { width: "100px !important", paddingRight: "0  !important" };

	return (
		<>
			<TableContainer className='roles-table card data-table'>
				<Table sx={{ minWidth: 650 }} aria-label='roles table'>
					<TableHead>
						<TableRow>
							{hideDelete ? null : <TableCell sx={style}>Action</TableCell>}
							<TableCell sx={style}>Resource</TableCell>
							<TableCell>Items</TableCell>
							<TableCell sx={style}>VIEW</TableCell>
							<TableCell sx={style}>CREATE</TableCell>
							<TableCell sx={style}>UPDATE</TableCell>
							<TableCell sx={style}>DELETE</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow key={row.ruleId} className='row'>
								{hideDelete ? null : (
									<TableCell sx={style}>{getActionButtons(row)}</TableCell>
								)}
								<TableCell sx={style}>{row.resourceType}</TableCell>
								<TableCell>{getItemsName(row.resource, row?.data)}</TableCell>
								<TableCell sx={style}>{row.read ? "YES" : "NO"}</TableCell>
								<TableCell sx={style}>{row.create ? "YES" : "NO"}</TableCell>
								<TableCell sx={style}>{row.edit ? "YES" : "NO"}</TableCell>
								<TableCell sx={style}>{row.delete ? "YES" : "NO"}</TableCell>
							</TableRow>
						))}
						{data?.length == 0 ? (
							<div style={{ padding: "1rem" }}>No data Found</div>
						) : null}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
