import { useLocation, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import Chart from "chart.js/auto";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import React from "react";
import { getRecipeDataById } from "../../../../Store/dashboard";
import { useSelector } from "react-redux";

// Try to eleminate importing auto CHart

// https://upmostly.com/tutorials/how-to-use-chart-js-with-react

const LineChart = ({ chartId }) => {
	// const { state = {} } = useLocation();
	// const { zoom = false } = state || {};
	const { id } = useParams();
	const recipe_data = useSelector(getRecipeDataById(id ? id : chartId));

	//let labels = Array.from({ length: 48 }, (v, i) => i);
	const isFullScreen = id !== undefined;
	// if (!isFullScreen) {
	// 	labels = Array.from({ length: 12 }, (v, i) => i * 4);
	// }
	const chartOptions = {
		responsive: isFullScreen,
	};
	const data = {
		labels: recipe_data?.prices?.map((obj) => obj.slot.split(' ')[0]) ?? [],
		datasets: [
			{
				label: "Prices",
				backgroundColor: "rgb(255, 99, 132)",
				borderColor: "rgb(255, 99, 132)",
				data: recipe_data?.prices?.map((obj) => obj.price) ?? [],
				clip: { left: 0, top: false, right: 10, bottom: 0 },
			},
		],
	};

	const options = {
		scales: {
			xAxes: [
				{
					scaleLabel: {
						display: true,
						labelString: "TEST LABEL", //recipe_data?.prices?.length > 0 ? (recipe_data?.prices[0].slot?.split(' ')[1] || 'GMT +00:00') : 'GMT +00:00' ?? 'GMT+00:00'
						fontColor: 'black'
					},
				},
			],
			yAxes: [
				{
				  ticks: {
					maxTicksLimit: 5, // Set the maximum number of y-ticks
				  },
				},
			  ],
		},
	};

	return (
		// <div className={zoom ? "zoom card-chart" : "zoom-out card-chart"}>
		// 	{zoom ? (
		// 		<Button
		// 			variant='contained'
		// 			component={Link}
		// 			to='/dashboard'
		// 			className='btn'
		// 			startIcon={<ChevronLeftIcon />}>
		// 			BACK
		// 		</Button>
		// 	) : null}
		<div
			style={{ width: "95%" }}
			className={isFullScreen ? "card full-page-chart" : ""}>
			<Line data={data} options={options} className={isFullScreen ? "chart" : "chart zoom-out"} />
		</div>
		// </div>
	);
};

export default LineChart;
