import * as React from "react";

import { Button, Divider, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { appAPI, useDeleteRoleMutation } from "../../../services/API/appAPI";
import { deleteModal, successModal } from "../Common/Modal";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import usePagination from "../Common/usePagination";

/**
 * TODO
 * 	- add [apply to niagara] btn, display if the recipe is in live and display pop up when clicked
 * 	- recipe confirmation pop up
 */

export default function BasicTable() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);
	const [rolesData, setRolesData] = React.useState();

	const roles = rolesData?.roles ?? [];

	const [deleteRole, {}] = useDeleteRoleMutation();

	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);

	async function fetchRoles() {
		try {
			setIsLoading(true);
			const { data } = await dispatch(
				appAPI.endpoints.getRoles.initiate(
					{
						count: entriesPerPage,
						page: currentPage,
					},
					{ forceRefetch: true }
				)
			);
			if (data) {
				setRolesData(data);
				const { totalCount = 0 } = data ?? {};
				setTotal(Number(totalCount));
				// const { resultCount = 0, currentPage = 0 } = data;
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchRoles();
	}, [entriesPerPage, currentPage]);

	async function deleteRoleFn(role) {
		if (role?.id) {
			setIsLoading(true);
			const { error } = await deleteRole({
				roleId: role.id,
			});
			setIsLoading(false);
			if (error == undefined) {
				successModal({
					message: `Role: ${role?.name} deleted successfully!`,
				});
				fetchRoles();
			}
		}
	}

	function onSelectRole(role) {
		console.log("onselect", role);
		navigate("/roles/" + role.id);
	}

	async function onClickDelete(role) {
		const { isConfirmed } = await deleteModal({
			title: `Delete role: ${role?.name}`,
		});
		if (isConfirmed) {
			deleteRoleFn(role);
		}
	}

	function getActionButtons(role) {
		const { showEdit = false, showDelete = false } = role;
		return (
			<Stack direction={"row"} spacing={0.5}>
				{showEdit ? (
					<EditIcon
						className='edit-icon'
						onClick={() => navigate("/roles/" + role.id + "/edit")}
					/>
				) : null}
				{showDelete ? (
					<>
						<Divider orientation='vertical' flexItem />
						<DeleteIcon
							className='delete-icon'
							onClick={() => onClickDelete(role)}
						/>
					</>
				) : null}
			</Stack>
		);
	}

	const { showCreate = false } = rolesData ?? {};

	if (isLoading) return <Loader />;
	const style = { width: "100px !important", paddingRight: "0  !important" };

	return (
		<>
			{showCreate ? (
				<Button
					variant='contained'
					component={Link}
					to='/roles/create'
					className='btn'
					startIcon={<AddIcon />}>
					Create Role
				</Button>
			) : null}
			<TableContainer className='roles-table card data-table'>
				<Table sx={{ minWidth: 650 }} aria-label='roles table'>
					<TableHead>
						<TableRow>
							<TableCell sx={style}>Action</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Users</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{roles?.map((role, index) => (
							<TableRow key={role.id} className='row'>
								<TableCell sx={style}>{getActionButtons(role)}</TableCell>
								<TableCell
									sx={{ width: "200px" }}
									className='hover'
									onClick={() => onSelectRole(role)}>
									{role.name}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectRole(role)}>
									{role.description}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectRole(role)}>
									{role?.users
										?.map(({ username = "" }) => username)
										?.join(" , ") ?? ""}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{render()}
			</TableContainer>
		</>
	);
}
