import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

// #111110 to #fff

export default function LOGOIcon() {
	return (
		<SvgIcon>
			<svg
				width='254.76'
				height='36'
				viewBox='0 0 254.76 36'
				className='logo-icon'>
				<defs>
					<clipPath id='clip-path'>
						<rect
							id='Rectangle_1'
							data-name='Rectangle 1'
							width='254.76'
							height='36'
							fill='none'
						/>
					</clipPath>
				</defs>
				<g id='Group_2' data-name='Group 2' transform='translate(0 0)'>
					<g
						id='Group_1'
						data-name='Group 1'
						transform='translate(0 0)'
						clipPath='url(#clip-path)'>
						<path
							id='Path_1'
							data-name='Path 1'
							d='M56.857,14.348a1,1,0,0,1,1.006-1.006h9.9a1.006,1.006,0,1,1,0,2.013H58.87v7.239h5.615a1.006,1.006,0,1,1,0,2.013H58.87v8.472a1.006,1.006,0,1,1-2.013,0Z'
							transform='translate(-15.247 -3.578)'
							fill='#4da565'
						/>
						<path
							id='Path_2'
							data-name='Path 2'
							d='M57.594,34.547a1.73,1.73,0,0,1-1.738-1.738V14.079a1.732,1.732,0,0,1,1.738-1.738h9.9a1.738,1.738,0,1,1,0,3.476H59.332v5.776h4.884a1.738,1.738,0,1,1,0,3.476H59.332v7.74a1.728,1.728,0,0,1-1.738,1.738'
							transform='translate(-14.978 -3.31)'
							fill='#4da565'
						/>
						<path
							id='Path_3'
							data-name='Path 3'
							d='M81.03,14.348a1.006,1.006,0,1,1,2.013,0V32.072h8.245a1.006,1.006,0,1,1,0,2.013H82.036a1,1,0,0,1-1.006-1.006Z'
							transform='translate(-21.729 -3.578)'
							fill='#4da565'
						/>
						<path
							id='Path_4'
							data-name='Path 4'
							d='M91.019,34.551H81.768a1.73,1.73,0,0,1-1.738-1.738V14.083a1.7,1.7,0,0,1,.507-1.231,1.772,1.772,0,0,1,2.463,0,1.7,1.7,0,0,1,.506,1.23V31.075h7.513a1.738,1.738,0,1,1,0,3.476'
							transform='translate(-21.461 -3.313)'
							fill='#4da565'
						/>
						<path
							id='Path_5'
							data-name='Path 5'
							d='M103.074,14.348a1,1,0,0,1,1.006-1.006h9.868a1.006,1.006,0,1,1,0,2.013h-8.862v7.239h5.908a1.006,1.006,0,1,1,0,2.013h-5.908v7.466h8.862a1.006,1.006,0,1,1,0,2.013H104.08a1,1,0,0,1-1.006-1.006Z'
							transform='translate(-27.64 -3.578)'
							fill='#4da565'
						/>
						<path
							id='Path_6'
							data-name='Path 6'
							d='M113.68,34.547h-9.868a1.73,1.73,0,0,1-1.738-1.738V14.079a1.732,1.732,0,0,1,1.738-1.738h9.868a1.738,1.738,0,1,1,0,3.476h-8.13v5.776h5.176a1.738,1.738,0,1,1,0,3.476H105.55v6h8.13a1.738,1.738,0,1,1,0,3.476'
							transform='translate(-27.372 -3.31)'
							fill='#4da565'
						/>
						<path
							id='Path_7'
							data-name='Path 7'
							d='M128.248,15.019a1.01,1.01,0,0,1,1.429-1.428l8.666,8.666,8.667-8.666a1.01,1.01,0,0,1,1.428,1.428l-8.666,8.667,8.666,8.667a1.01,1.01,0,0,1-1.428,1.428l-8.667-8.667-8.666,8.667a1.01,1.01,0,0,1-1.429-1.428l8.667-8.667Z'
							transform='translate(-34.313 -3.566)'
							fill='#4da565'
						/>
						<path
							id='Path_8'
							data-name='Path 8'
							d='M147.456,34.539a1.706,1.706,0,0,1-1.232-.506l-8.149-8.149-8.15,8.149a1.773,1.773,0,0,1-2.463,0,1.75,1.75,0,0,1,0-2.463l8.149-8.148-8.15-8.151a1.748,1.748,0,0,1,0-2.462,1.771,1.771,0,0,1,2.463,0l8.15,8.148,8.149-8.148a1.77,1.77,0,0,1,2.463,0,1.747,1.747,0,0,1,0,2.46l-8.15,8.151,8.15,8.148a1.738,1.738,0,0,1-1.232,2.97'
							transform='translate(-34.044 -3.301)'
							fill='#4da565'
						/>
						<path
							id='Path_9'
							data-name='Path 9'
							d='M164.649,13.539a1.06,1.06,0,0,1,1.068-1.068h10.508a1.068,1.068,0,1,1,0,2.136h-9.439v7.683h5.96a1.068,1.068,0,1,1,0,2.136h-5.96v8.991a1.068,1.068,0,1,1-2.136,0Z'
							transform='translate(-44.152 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_10'
							data-name='Path 10'
							d='M188.513,13.264a1.432,1.432,0,0,1,.378-.534.884.884,0,0,1,.621-.259.924.924,0,0,1,.621.242,1.589,1.589,0,0,1,.413.551L199.054,33a.966.966,0,0,1,.07.413,1.058,1.058,0,0,1-.276.724.965.965,0,0,1-.758.31,1,1,0,0,1-1-.655l-2.755-6.3h-9.681L181.9,33.8a1.06,1.06,0,0,1-.413.482,1.082,1.082,0,0,1-.586.173,1.03,1.03,0,0,1-1.068-1.034.92.92,0,0,1,.1-.413Zm1,3.2-3.928,8.888h7.82Z'
							transform='translate(-48.223 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_11'
							data-name='Path 11'
							d='M213.136,13.573a1.088,1.088,0,0,1,.31-.775,1,1,0,0,1,.758-.328h5.65a5.726,5.726,0,0,1,2.308.465,6.045,6.045,0,0,1,3.152,3.152,5.8,5.8,0,0,1,.086,4.341,6.208,6.208,0,0,1-1.033,1.74,6.008,6.008,0,0,1-1.568,1.309,5.682,5.682,0,0,1-1.946.706l5.236,8.509a2.864,2.864,0,0,1,.19.379,1.17,1.17,0,0,1,.086.447,1.074,1.074,0,0,1-.259.621.869.869,0,0,1-.74.345,1,1,0,0,1-.569-.173,1.438,1.438,0,0,1-.43-.448l-5.891-9.611h-3.2v9.163a1.068,1.068,0,1,1-2.136,0Zm2.136,1.033v7.511h4.582a3.66,3.66,0,0,0,1.446-.293,3.76,3.76,0,0,0,1.206-.809,3.819,3.819,0,0,0,.809-1.189,3.619,3.619,0,0,0,.293-1.464,3.66,3.66,0,0,0-.293-1.447A3.767,3.767,0,0,0,221.3,14.9a3.672,3.672,0,0,0-1.446-.293Z'
							transform='translate(-57.154 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_12'
							data-name='Path 12'
							d='M241.874,13.367a1.094,1.094,0,0,1,.362-.637,1.033,1.033,0,0,1,.706-.258,1,1,0,0,1,1,.654l6.993,17.432,6.959-17.432a1.072,1.072,0,0,1,1.705-.378,1.192,1.192,0,0,1,.362.689l2.411,19.878v.172a.994.994,0,0,1-.276.706.868.868,0,0,1-.654.293,1.2,1.2,0,0,1-.792-.258,1.056,1.056,0,0,1-.38-.637l-1.928-15.847-6.374,16.019a1.59,1.59,0,0,1-.413.552.918.918,0,0,1-.621.241.884.884,0,0,1-.62-.258,1.423,1.423,0,0,1-.379-.534L243.424,17.57l-2.274,15.985a1.077,1.077,0,0,1-.378.672,1.054,1.054,0,0,1-.689.258,1.373,1.373,0,0,1-.774-.344.9.9,0,0,1-.293-.69.149.149,0,0,1,.018-.068.25.25,0,0,0,.017-.1Z'
							transform='translate(-64.094 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_13'
							data-name='Path 13'
							d='M278.581,13.539a1.068,1.068,0,1,1,2.136,0V33.417a1.068,1.068,0,1,1-2.136,0Z'
							transform='translate(-74.704 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_14'
							data-name='Path 14'
							d='M289.29,13.539a1.06,1.06,0,0,1,1.068-1.068.884.884,0,0,1,.569.155,2.5,2.5,0,0,1,.4.43l11.851,16.777V13.539a1.068,1.068,0,1,1,2.136,0V33.417a1.059,1.059,0,0,1-1.068,1.068.876.876,0,0,1-.621-.224,2.456,2.456,0,0,1-.413-.5L291.426,17.087v16.33a1.068,1.068,0,1,1-2.136,0Z'
							transform='translate(-77.576 -3.344)'
							fill='#fff'
						/>
						<path
							id='Path_15'
							data-name='Path 15'
							d='M332.874,26.586a1.068,1.068,0,1,1,0-2.136h6.235a.944.944,0,0,1,.759.327,1.17,1.17,0,0,1,.274.775.59.59,0,0,1-.016.138.523.523,0,0,0-.018.138,10.772,10.772,0,0,1-3.772,6.218,11.216,11.216,0,0,1-3.221,1.808,10.845,10.845,0,0,1-3.755.654,10.676,10.676,0,0,1-4.272-.861,10.952,10.952,0,0,1-5.822-5.822,11.025,11.025,0,0,1,0-8.544,11.266,11.266,0,0,1,2.343-3.5,10.909,10.909,0,0,1,3.478-2.36,11.035,11.035,0,0,1,9.354.379,10.953,10.953,0,0,1,3.806,3.307,1.358,1.358,0,0,1,.241.724,1.034,1.034,0,0,1-.276.672.936.936,0,0,1-.757.327,1.343,1.343,0,0,1-.5-.1,1.1,1.1,0,0,1-.465-.379,10.672,10.672,0,0,0-3.478-2.791,8.51,8.51,0,0,0-3.653-.861,8.761,8.761,0,0,0-6.252,2.583,9.008,9.008,0,0,0-1.879,2.826,8.807,8.807,0,0,0,8.131,12.264,8.586,8.586,0,0,0,2.739-.431,9.216,9.216,0,0,0,2.378-1.189,8.377,8.377,0,0,0,1.894-1.826,9.323,9.323,0,0,0,1.292-2.342Z'
							transform='translate(-85.383 -3.37)'
							fill='#fff'
						/>
						<path
							id='Path_16'
							data-name='Path 16'
							d='M12.419,37.556a12.578,12.578,0,0,1-9.5-4.177c-3.84-4.474-3.9-11.021-.165-18.932A48.277,48.277,0,0,1,8.095,6.237a1.134,1.134,0,1,1,1.783,1.4,45.962,45.962,0,0,0-5.07,7.778C2.491,20.326.582,27.173,4.643,31.9a10.021,10.021,0,0,0,6.72,3.325l-.22-6.27L7.986,31.533a1.14,1.14,0,0,1-.831.25,1.134,1.134,0,0,1-.6-2.006l4.922-4.017a1.135,1.135,0,0,1,1.6.161,1.422,1.422,0,0,1,.274.776l.3,9.651a1.261,1.261,0,0,1-1.221,1.209'
							transform='translate(0 -1.556)'
							fill='#4da565'
						/>
						<path
							id='Path_17'
							data-name='Path 17'
							d='M21.134,31.727a1.123,1.123,0,0,1-.719-.258l-6.066-5-6.01,4.86a1.136,1.136,0,0,1-1.595-.168h0a1.133,1.133,0,0,1,.169-1.594l6.729-5.443a1.129,1.129,0,0,1,1.366-.04c.028.019.1.067,6.467,5.318l.381.314a1.133,1.133,0,0,1-.722,2.008'
							transform='translate(-1.741 -6.403)'
							fill='#4da565'
						/>
						<path
							id='Path_18'
							data-name='Path 18'
							d='M9.432,21.938a1.134,1.134,0,0,1-.744-1.989l2.053-1.782a1.134,1.134,0,1,1,1.486,1.713l-2.053,1.782a1.128,1.128,0,0,1-.742.277'
							transform='translate(-2.225 -4.797)'
							fill='#4da565'
						/>
						<path
							id='Path_19'
							data-name='Path 19'
							d='M23.424,39.344a1.126,1.126,0,0,1-.742-.278l-2.052-1.781a1.138,1.138,0,0,1-.113-1.6,1.125,1.125,0,0,1,.776-.387,1.106,1.106,0,0,1,.823.275l2.053,1.781a1.135,1.135,0,0,1-.664,1.988c-.028,0-.055,0-.081,0'
							transform='translate(-5.428 -9.464)'
							fill='#4da565'
						/>
						<path
							id='Path_20'
							data-name='Path 20'
							d='M20.212,35.6a1.134,1.134,0,0,1-.273-2.235,8.615,8.615,0,0,0,4.714-3.055c4.06-4.73,2.152-11.577-.165-16.488a49.574,49.574,0,0,0-6.581-9.572v7.689l4.531,3.7A1.133,1.133,0,1,1,21,17.388l-4.922-4.016a1.3,1.3,0,0,1-.436-.8l.009-11.341A1.133,1.133,0,0,1,17.659.419c.149.182.364.436.632.752,1.855,2.2,6.2,7.338,8.249,11.679,3.732,7.911,3.675,14.459-.165,18.932a10.863,10.863,0,0,1-5.89,3.781,1.158,1.158,0,0,1-.272.033'
							transform='translate(-4.195 0)'
							fill='#4da565'
						/>
					</g>
				</g>
			</svg>
		</SvgIcon>
	);
}
