import * as Yup from "yup";

import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Loader from "../MasterPage/Pages/Common/Loader";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { saveUserInfo } from "../MasterPage/Pages/Common/localStorageHelpers";
import styled from "@emotion/styled";
import { updateUser } from "../Store/user";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useLoginMutation } from "../services/API/appAPI";
import { useNavigate } from "react-router-dom";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

// import { API } from "../services/API";

// import {
// 	checkCookie,
// 	isCookieExpired,
// } from "../MasterPage/Pages/Common/AuthorizedRoute";

const Field = styled.span`
	position: relative;
	.error {
		position: absolute;
		color: red;
		left: 0;
		top: 70px;
	}
`;

export default function SignIn() {
	const [isLoading, setIsLoading] = useState(false);
	// const { data, error, isLoading } = useLoginQuery({
	// 	username: "admin",
	// 	password: "Gowtam#143",
	// });

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [login, {}] = useLoginMutation();
	// handleRequestErrors({ error, isError, navigate });

	useEffect(() => {
		// var cookieName = "connect.sid";
		// if (checkCookie(cookieName)) {
		// 	if (isCookieExpired(cookieName)) {
		// 		console.log("The cookie has expired.");
		// 	} else {
		// 		console.log("The cookie exists and is not expired.");
		// 		navigate("/");
		// 	}
		// } else {
		// 	console.log("The cookie does not exist.");
		// }
	}, []);

	const schema = Yup.object().shape({
		userName: Yup.string().required("User Name is Required*"),
		password: Yup.string().required("Password is Required*"),
	});

	const formik = useFormik({
		initialValues: {
			userName: "",
			password: "",
		},
		validationSchema: schema,
		onSubmit: ({ userName, password }) => {
			try {
				setIsLoading(true);
				async function init() {
					const { error, data } = await login({
						body: {
							username: userName,
							password,
						},
					});
					if (error == undefined) {
						navigate("/");
						saveUserInfo(data);
					}
				}
				init();
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		},
	});

	if (isLoading) return <Loader />;

	return (
		<Container component='main' maxWidth='xs' className='login-form'>
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Typography component='h1' variant='h5'>
					Sign in
				</Typography>
				<Box component='form' noValidate sx={{ mt: 1 }}>
					<Field>
						<TextField
							value={formik.values.userName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							margin='normal'
							fullWidth
							id='user-name'
							label='User Name'
							name='userName'
							autoFocus
						/>
						{formik.touched?.userName && formik.errors.userName ? (
							<span className='error'>{formik.errors.userName}</span>
						) : null}
					</Field>
					<Field>
						<TextField
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							margin='normal'
							fullWidth
							name='password'
							label='Password'
							type='password'
							id='password'
						/>
						{formik.touched?.password && formik.errors.password ? (
							<span className='error'>{formik.errors.password}</span>
						) : null}
					</Field>
					{/* <FormControlLabel
						control={<Checkbox value='remember' color='primary' />}
						label='Remember me'
					/> */}
					<Button
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
						onClick={formik.handleSubmit}>
						Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Link href='#' variant='body2'>
								Forgot password?
							</Link>
						</Grid>
						{/* <Grid item>
							<Link href='#' variant='body2'>
								{"Don't have an account? Sign Up"}
							</Link>
						</Grid> */}
					</Grid>
				</Box>
			</Box>
		</Container>
	);
}
