import * as React from "react";

import { Button, Divider, Stack, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
	appAPI,
	useDeleteRoomMutation,
	usePushBaseRecipeMutation,
} from "../../../services/API/appAPI";
import { deleteModal, successModal } from "../Common/Modal";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TagIcon from "@mui/icons-material/Tag";
import { useDispatch } from "react-redux";
import usePagination from "../Common/usePagination";

export default function BasicTable() {
	const [isLoading, setIsLoading] = React.useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [roomsData, setRoomsData] = React.useState({});

	const { rooms = [] } = roomsData ?? {};
	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);
	const [deleteRoom, {}] = useDeleteRoomMutation();
	const [pushBaseRecipe, pushBaseRecipeObj] = usePushBaseRecipeMutation();

	React.useEffect(() => {
		fetchRooms();
	}, [currentPage, entriesPerPage]);

	async function fetchRooms() {
		setIsLoading(true);
		const { data } = await dispatch(
			appAPI.endpoints.getRooms.initiate(
				{
					count: entriesPerPage,
					page: currentPage,
				},
				{ forceRefetch: true }
			)
		);
		if (data) {
			setRoomsData(data);
			const { totalCount = 0 } = data ?? {};
			setTotal(Number(totalCount));
		}
		setIsLoading(false);
	}

	async function deleteRoomFn(room) {
		if (room?.id) {
			const { isConfirmed } = await deleteModal({
				title: `Delete room: ${room?.name}`,
			});
			if (isConfirmed) {
				setIsLoading(true);
				const { error } = await deleteRoom({
					roomId: room?.id,
				});
				setIsLoading(false);
				if (error == undefined) {
					successModal({
						message: `Room: ${room?.name} deleted successfully`,
					});
					fetchRooms();
				}
			}
		}
	}

	async function pushRoomFn(room) {
		if (room?.id) {
			const { error } = await pushBaseRecipe({
				roomId: room?.id,
			});
			if (error == undefined) {
				successModal({
					message: `Room: ${room?.name} pushed successfully`,
				});
				fetchRooms();
			}
		}
	}

	function onSelectRoom(room) {
		console.log("onselect", room);
		navigate("/rooms/" + room.id);
	}

	function getActionButtons(room) {
		const { showEdit = false, showDelete = false } = room;
		return (
			<Stack direction={"row"} spacing={0.5}>
				{showEdit ? (
					<EditIcon
						className='edit-icon'
						onClick={() => navigate("/rooms/" + room.id + "/edit")}
					/>
				) : null}
				{showDelete ? (
					<>
						<Divider orientation='vertical' flexItem />
						<DeleteIcon
							className='delete-icon'
							onClick={() => deleteRoomFn(room)}
						/>
					</>
				) : null}
				<Divider orientation='vertical' flexItem />
				<Tooltip title='Push Base Recipe' arrow>
					<TagIcon
						className='hash-for-push-icon'
						onClick={() => pushRoomFn(room)}
					/>
				</Tooltip>
			</Stack>
		);
	}

	if (isLoading) return <Loader />;

	const { showCreate = false } = roomsData ?? {};

	const style = { width: "100px !important", paddingRight: "0  !important" };

	return (
		<>
			<Loader open={pushBaseRecipeObj?.isLoading} />
			{showCreate ? (
				<Button
					variant='contained'
					component={Link}
					to='/rooms/create'
					className='btn'
					startIcon={<AddIcon />}>
					Create Room
				</Button>
			) : null}
			<TableContainer className='rooms-table card data-table'>
				<Table aria-label='rooms table'>
					<TableHead>
						<TableRow>
							<TableCell sx={style}>Action</TableCell>
							<TableCell sx={style}>Active</TableCell>
							<TableCell>Room Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell sx={style}>Mode</TableCell>
							<TableCell>MQTT Topic</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rooms.map((room) => (
							<TableRow key={room.id} className='row'>
								<TableCell sx={style}>{getActionButtons(room)}</TableCell>
								<TableCell
									className='hover'
									onClick={() => onSelectRoom(room)}
									sx={style}>
									{room?.state?.toLowerCase() === "active" ? "YES" : "NO"}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectRoom(room)}>
									{room.name}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectRoom(room)}>
									{room.description}
								</TableCell>
								<TableCell
									className='hover'
									onClick={() => onSelectRoom(room)}
									sx={style}>
									{room.mode}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectRoom(room)}>
									{room.mqttTopic}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{render()}
			</TableContainer>
		</>
	);
}
