import {
	Badge,
	Box,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	Popover,
	TextField,
	Typography,
} from "@mui/material";
import { Grid, Stack } from "@mui/material";
import { Link, useHref, useNavigate } from "react-router-dom";
import React, { createRef, useMemo, useRef, useState } from "react";

import { Breadcrumbs } from "@mui/material";
import { CardActionArea } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersomIconMenu from "./PersonIconMenu";
import SearchIcon from "@mui/icons-material/Search";
import { useLazyAppSearchQuery } from "../services/API/appAPI";

export default function TopMenu() {
	const [search, setSearch] = useState("");
	const navigate = useNavigate();
	const location = useHref();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const [appSearch, result] = useLazyAppSearchQuery();

	const handleClose = () => {
		setAnchorEl(null);
	};

	async function searchALL(term = search, event) {
		const { data } = await appSearch({ searchterm: term });
	}

	function onClick() {
		searchALL(search);
		setAnchorEl(document.getElementById("search"));
	}

	const availableOptions = useMemo(() => {
		const { recipe = [], role = [], room = [], user = [] } = result?.data ?? {};
		return [
			...recipe.map((obj) => {
				return {
					...obj,
					title: "RECIPE",
					path: `recipes/${obj?.id}`,
					color: "orange",
				};
			}),
			...role.map((obj) => {
				return {
					...obj,
					title: "ROLE",
					path: `roles/${obj?.id}`,
					color: "purple",
				};
			}),
			...room.map((obj) => {
				return {
					...obj,
					title: "ROOM",
					path: `rooms/${obj?.id}`,
					color: "green",
				};
			}),
			...user.map((obj) => {
				return {
					...obj,
					title: "USER",
					path: `users/${obj?.id}`,
					color: "blue",
				};
			}),
		];
	}, [result]);

	function getBreadCrumb() {
		if (location == "/") return null;
		let links = location.split("/");
		links = links.map((text, index) => {
			return { data: text, _link: links.slice(0, index + 1).join("/") };
		});

		links.splice(0, 1);
		return links.map((obj) => (
			<Link underline='hover' color='inherit' to={obj._link} key={obj._link}>
				{obj.data}
			</Link>
		));
	}

	function getHeading() {
		if (location == "/") return "Dashboard";
		const array = location.split("/");
		if (array[array.length - 1] === "reciperoomlink")
			return "Recipe To Room Link";
		return location.split("/")[1];
	}

	return (
		<header className='top_container'>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={{ xs: 2, sm: 3 }}>
				<div>
					<Breadcrumbs aria-label='current path' className='breadcrumb'>
						<Link underline='hover' color='inherit' href='/'>
							<HomeIcon fontSize='small' className='home-icon' />
						</Link>
						{getBreadCrumb()}
					</Breadcrumbs>
					<p className='heading'>{getHeading()}</p>
				</div>
				<Stack
					direction='row'
					justifyContent='right'
					alignItems='center'
					spacing={{ xs: 2, sm: 3 }}>
					<TextField
						variant='outlined'
						label='Search'
						id='search'
						size='small'
						className='search-field'
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment
									className='search-icon'
									position='end'
									onClick={onClick}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						aria-describedby='search-label'
					/>
					{availableOptions?.length > 0 && (
						<Popover
							id={"search"}
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}>
							<List
								sx={{
									width: "20rem",
									bgcolor: "background.paper",
								}}>
								{availableOptions.map((value = {}, index) => {
									const { id, name, username, title, path, color } = value;
									console.log(index);
									return (
										<ListItem
											key={id}
											disableGutters
											// secondaryAction={
											// 	<IconButton aria-label='comment'>
											// 		<CommentIcon />
											// 	</IconButton>
											// }
											onClick={() => {
												navigate(path);
												handleClose();
											}}
											sx={{
												"&:hover": {
													backgroundColor: "#e9e9e9",
													cursor: "pointer",
												},
											}}>
											<Box
												sx={{
													padding: "0 0.7rem",
												}}>
												<Typography
													component={"span"}
													sx={{ color, fontSize: "0.7rem", fontWeight: 600 }}>
													{title}
												</Typography>
												&nbsp;&nbsp;&nbsp;&nbsp;
												<Typography
													component={"span"}
													sx={{ fontSize: "1.1rem" }}>
													{" "}
													{name ?? username}
												</Typography>
											</Box>
										</ListItem>
									);
								})}
							</List>
						</Popover>
					)}
					{/* <InputLabel id='search-label' htmlFor='search'>
                        Search
                    </InputLabel> */}
					{/* <CardActionArea className='badge-wrapper'>
						<Badge badgeContent={4} color='primary' className='menu-icon'>
							<NotificationsIcon fontSize='medium' />
						</Badge>
					</CardActionArea> */}
					<PersomIconMenu />
				</Stack>
			</Stack>
		</header>
	);
}
