import React, { useEffect } from "react";

import EditUser from "../Users/EditUser";
import { getUserInfo } from "../Common/localStorageHelpers";

const Profile = () => {
	const user = getUserInfo();
	console.log(user);
	const id = user?.id ?? "";
	if (!id) {
		return <div>No Data Found</div>;
	}
	return <EditUser id={id} isProfile={true} />;
};

export default Profile;
