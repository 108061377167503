import * as React from "react";

import { Button, Divider, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { appAPI, useDeleteUserMutation } from "../../../services/API/appAPI";
import { deleteModal, successModal } from "../Common/Modal";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDate } from "../Common/utilityFns";
import { useDispatch } from "react-redux";
import usePagination from "../Common/usePagination";

export default function UsersList() {
	const [usersData, setUsersData] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(false);

	const [deleteUser, {}] = useDeleteUserMutation();

	const dispatch = useDispatch();
	const users = usersData?.users ?? [];

	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);

	async function fetchUsers() {
		setIsLoading(true);
		const { data } = await dispatch(
			appAPI.endpoints.getUsers.initiate(
				{
					count: entriesPerPage,
					page: currentPage,
				},
				{ forceRefetch: true }
			)
		);
		if (data) {
			setUsersData(data);
			const { totalCount = 0 } = data ?? {};
			setTotal(Number(totalCount));
		}
		setIsLoading(false);
	}

	React.useEffect(() => {
		fetchUsers();
		// refetch({
		// 	count: entriesPerPage,
		// 	page: currentPage,
		// });
		// refetch is not taking the latest entries perpage and current page.
		//https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#re-fetching-on-demand-with-refetchinitiate
	}, [currentPage, entriesPerPage]);

	async function deleteUserFn(user) {
		if (user?.id) {
			setIsLoading(true);
			const { error } = await deleteUser({
				userId: user?.id,
			});
			setIsLoading(false);
			if (error == undefined) {
				successModal({
					message: `User: ${user?.username} deleted successfully.!`,
				});
				fetchUsers();
			}
		}
	}

	const navigate = useNavigate();

	function onSelectUser(user) {
		console.log("onselect", user);
		navigate("/users/" + user.id);
	}

	async function onClickDelete(user) {
		const { isConfirmed } = await deleteModal({
			title: `Delete user: ${user?.username}`,
		});
		if (isConfirmed) {
			console.log("delete the user");
			deleteUserFn(user);
		}
	}

	function getActionButtons(user) {
		const { showEdit = false, showDelete = false } = user;
		return (
			<Stack direction={"row"} spacing={0.5}>
				{showEdit ? (
					<EditIcon
						className='edit-icon'
						onClick={() => navigate("/users/" + user.id + "/edit")}
					/>
				) : null}
				{showDelete ? (
					<>
						<Divider orientation='vertical' flexItem />
						<DeleteIcon
							className='delete-icon'
							onClick={() => onClickDelete(user)}
						/>
					</>
				) : null}
			</Stack>
		);
	}

	if (isLoading) return <Loader />;

	const { showCreate = false } = usersData ?? {};

	const timeWidth = "120px";

	return (
		<>
			{showCreate ? (
				<Button
					variant='contained'
					component={Link}
					to='/users/create'
					className='btn'
					startIcon={<AddIcon />}>
					Create User
				</Button>
			) : null}
			<TableContainer className='users-table card data-table'>
				<Table sx={{ minWidth: 650 }} aria-label='users table'>
					<TableHead>
						<TableRow>
							<TableCell>Action</TableCell>
							<TableCell>Active</TableCell>
							<TableCell>Full Name</TableCell>
							<TableCell>Login Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Role</TableCell>
							<TableCell sx={{ width: timeWidth }}>Created Date</TableCell>
							<TableCell sx={{ width: timeWidth }}>Modified Date</TableCell>
							<TableCell sx={{ width: timeWidth }}>Last Login</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user, index) => (
							<TableRow
								key={user?.email + user?.firstName + user?.lastName}
								className='row'>
								<TableCell>{getActionButtons(user)}</TableCell>
								<TableCell className='hover' onClick={() => onSelectUser(user)}>
									{user?.state != "active" ? "NO" : "YES"}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectUser(user)}>
									{user?.firstName + " " + user?.lastName}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectUser(user)}>
									{user?.username}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectUser(user)}>
									{user?.email}
								</TableCell>
								<TableCell className='hover' onClick={() => onSelectUser(user)}>
									{user?.roles.join(",")}
								</TableCell>
								<TableCell
									className='hover'
									onClick={() => onSelectUser(user)}
									sx={{ width: timeWidth }}>
									{formatDate(user?.created)}
								</TableCell>
								<TableCell
									className='hover'
									onClick={() => onSelectUser(user)}
									sx={{ width: timeWidth }}>
									{formatDate(user?.lastModified)}
								</TableCell>
								<TableCell
									className='hover'
									onClick={() => onSelectUser(user)}
									sx={{ width: timeWidth }}>
									{user?.lastSuccessfullLogin?.length > 0
										? formatDate(user?.lastSuccessfullLogin)
										: "Never"}
									{/**here we need last login user name */}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{render()}
			</TableContainer>
		</>
	);
}
