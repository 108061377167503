import * as React from "react";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	components: {
		MuiTableCell: {
			styleOverrides: {
				root: {
					maxWidth: "180px", // Adjust as needed
					overflow: "hidden",
					width: "fit-content",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					// To wrap the word if crosses
					wordWrap: "break-word",
					textWrap: "wrap",
					verticalAlign: "text-top",
				},
			},
		},
		// MuiInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			wrap: "true",
		// 		},
		// 	},
		// },
	},
});

export default theme;
