import { appAPI } from "../services/API/appAPI";
import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./dashboard";
import { rtkQueryErrorLogger } from "./errorHandlerMiddleWare";
import { setupListeners } from "@reduxjs/toolkit/query";

// import userReducer from "./user";

const store = configureStore({
	reducer: {
		[appAPI.reducerPath]: appAPI.reducer,
		// users: usersReducer,
		// recipes: recipesReducer,
		// rooms: roomsReducer,
		// roles: rolesReducer,
		// modal: modalReducer,
		dashboard: dashboardReducer,
		// user: userReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(appAPI.middleware)
			.concat(rtkQueryErrorLogger),
});

export default store;

// https://redux.js.org/tutorials/essentials/part-6-performance-normalization
setupListeners(store.dispatch);
