import * as yup from "yup";

import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { questionModal, successModal } from "../Common/Modal";
import {
	useGetResourcesByTypeMutation,
	useLazyGetActiveRecipesOfRoomQuery,
	useLazyGetRecipeTypesQuery,
	useLazyGetRecipesRoomsAssociationQuery,
	useRecipeRoomAssociateMutation,
} from "../../../services/API/appAPI";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilteredRecipeLinkTable from "./FilteredRecipeLinkTable";
import { Formik } from "formik";
import Load from "../Common/Loader";
import { useNavigate } from "react-router-dom";

const styles = {
	display: "flex",
	flexDirection: "column",
	height: "100%",
};

export default function RecipeRoomLink() {
	const [next, setNext] = useState(false);
	const [loadAssociations, setLoadAssociations] = useState(false);
	const [rooms, setRooms] = useState([]);
	const [
		getRecipeTypes,
		{
			isLoading: loadRecipeTypes,
			isFetching: fetchRecipeTypes,
			currentData: recipeTypes = [],
		},
	] = useLazyGetRecipeTypesQuery();
	const [
		getResourcesByType,
		{ isLoading: loadGetResourcesByType, data: recipes = [] },
	] = useGetResourcesByTypeMutation();

	const [recipeRoomAssociate, { isLoading: loadAssociate }] =
		useRecipeRoomAssociateMutation();

	const [
		getActiverecipes,
		{ isLoading: loadActiverecipes, isFetching: fetchActiverecipes },
	] = useLazyGetActiveRecipesOfRoomQuery();

	const navigate = useNavigate();
	useEffect(() => {
		async function FillForm() {
			await getRecipeTypes();
			const { data: rooms = [] } = await getResourcesByType({
				resourceType: "room",
			});
			await getResourcesByType({ resourceType: "recipe" });
			setRooms(rooms);
		}
		FillForm();
	}, []);
	if (
		loadRecipeTypes ||
		fetchRecipeTypes ||
		loadGetResourcesByType ||
		loadAssociate
	)
		return <Load />;

	const initFormik = {
		type: "",
		recipeId: "",
		roomName: "",
		roomId: "",
		processingTime: "22:00",
		pushTime: "23:00",
		active: false,
	};

	return (
		<>
			<Load open={loadActiverecipes || fetchActiverecipes} />
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/recipes")}>
				BACK
			</Button>
			<Formik
				initialValues={initFormik}
				validationSchema={yup.object().shape({
					type: yup.string().required("Recipe Type is required*"),
					recipeId: yup.string().required("Recipe Name is required*"),
					roomId: yup.string().required("Room is required*"),
					processingTime: yup.string().required("Processing Time is required*"),
					pushTime: yup.string().required("Push Time is required*"),
					active: yup.boolean(),
				})}
				onSubmit={async (values) => {
					const active = values.active;
					const roomName =
						rooms?.find(({ id }) => id === values.roomId)?.name ?? "";

					const recipeName =
						recipes?.find(({ id }) => id === values.recipeId)?.name ?? "";
					if (active && values.roomId) {
						const { data: activeRecipes = [] } = await getActiverecipes({
							roomId: values.roomId,
						});
						let activeRecipeName;
						const check =
							activeRecipes?.length > 0
								? activeRecipes?.every((recipe) => {
										activeRecipeName = recipe?.recipename ?? "";
										return recipe?.recipeid !== values?.recipeId;
								  })
								: false;
						if (check) {
							const { isConfirmed } = await questionModal({
								message: [
									`Room: ${roomName} has recipe: ${activeRecipeName} as default (active) recipe.`,
									`By proceeding,  recipe: ${recipeName} will become the active recipe.`,
									`Do you want to proceed?`,
								],
							});
							if (!isConfirmed) return;
						}
					}
					const processingTime = Number(
						values?.processingTime?.replace(/:/g, "")
					);
					const pushTime = Number(values?.pushTime?.replace(/:/g, ""));
					const { data, error } = await recipeRoomAssociate({
						...values,
						processingTime,
						pushTime,
						roomName,
					});
					const msg = data?.message ?? "";
					if (error == undefined) {
						successModal({
							message: msg,
						}).then(() => {
							setLoadAssociations((s) => !s);
						});
					}
				}}>
				{(formik) => (
					<Stack
						direction={{ xs: "column", sm: "row" }}
						spacing={2}
						className='recipe-room-link-form'>
						{/* <Card
							className='card'
							sx={{
								// "@media (min-width: 600px)": {
								// 	minWidth: "400px",
								// 	height: "fit-content",
								// },
								paddingBottom: "1rem",
								"@media (min-width: 900px)": {
									minWidth: "400px",
								},
							}}>
							<CardContent style={styles}>
								
								<Stack direction={"row"} justifyContent={"center"}>
									<Button
										variant='contained'
										className='btn'
										type='submit'
										endIcon={<ArrowForwardIosIcon />}
										onClick={() => {
											const _next =
												formik.values.type && formik.values.recipeId;
											setNext(_next);
										}}
										sx={{ marginBottom: "1rem" }}
										disabled={!(formik.values.type && formik.values.recipeId)}>
										Next
									</Button>
								</Stack>
							</CardContent>
						</Card> */}
						<Card className='card' sx={{ width: "100%" }}>
							<CardContent style={styles}>
								<Stack direction={"row"} justifyContent={"space-between"}>
									<Stack spacing={2.5} sx={{ width: "40%" }}>
										<FormControl size='small' fullWidth>
											<InputLabel id='type-id'>Type</InputLabel>
											<Select
												labelId='type-id'
												label='Type'
												name='type'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.type}>
												{recipeTypes?.map((type) => (
													<MenuItem value={type} key={type}>
														{type}
													</MenuItem>
												))}
											</Select>
											{formik.touched?.type && formik.errors.type ? (
												<span className='error'>{formik.errors.type}</span>
											) : null}
										</FormControl>
										<FormControl size='small' fullWidth>
											<InputLabel id='recipe-name'>Recipe Name</InputLabel>
											<Select
												labelId='recipe-name'
												label='Recipe Name'
												name='recipeId'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.recipeId}>
												{recipes?.map((recipe) => (
													<MenuItem value={recipe?.id} key={recipe?.id}>
														{recipe?.name ?? ""}
													</MenuItem>
												))}
											</Select>
											{formik.touched?.recipeId && formik.errors.recipeId ? (
												<span className='error'>{formik.errors.recipeId}</span>
											) : null}
										</FormControl>
										<FormControl size='small' fullWidth>
											<InputLabel id='location-id'>Room</InputLabel>
											<Select
												labelId='location-id'
												label='Location'
												value={formik.values.roomId}
												name='roomId'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}>
												{rooms?.map((room) => (
													<MenuItem value={room?.id} key={room?.id}>
														{room?.name ?? ""}
													</MenuItem>
												))}
											</Select>
											{formik.touched?.roomId && formik.errors.roomId ? (
												<span className='error'>{formik.errors.roomId}</span>
											) : null}
										</FormControl>
									</Stack>
									<Stack spacing={2.5} sx={{ width: "40%" }}>
										<div>
											<span>Active</span>
											<Checkbox
												{...{
													inputProps: { "aria-label": "Checkbox Active" },
												}}
												name='active'
												checked={formik.values.active}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</div>
										<Stack
											direction={"row"}
											spacing={3}
											alignItems={"center"}
											justifyContent={"space-between"}
											sx={{ width: "20rem" }}>
											<span>Recipe Processing Time</span>
											<OutlinedInput
												size='small'
												type='time'
												variant='outlined'
												value={formik.values.processingTime}
												name='processingTime'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												step='3600'
											/>
											{formik.touched?.processingTime &&
											formik.errors.processingTime ? (
												<span className='error'>
													{formik.errors.processingTime}
												</span>
											) : null}
										</Stack>
										<Stack
											direction={"row"}
											spacing={3}
											alignItems={"center"}
											justifyContent={"space-between"}
											sx={{ width: "20rem" }}>
											<span>MQTT Push Time</span>
											<OutlinedInput
												size='small'
												type='time'
												variant='outlined'
												value={formik.values.pushTime}
												name='pushTime'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												step='3600'
											/>
											{formik.touched?.pushTime && formik.errors.pushTime ? (
												<span className='error'>{formik.errors.pushTime}</span>
											) : null}
										</Stack>
									</Stack>
								</Stack>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									sx={{ marginTop: "1rem" }}>
									<Button
										variant='contained'
										className='btn'
										type='submit'
										endIcon={<ArrowForwardIosIcon />}
										onClick={formik.handleSubmit}>
										Create Link
									</Button>
								</Stack>
							</CardContent>
						</Card>
					</Stack>
				)}
			</Formik>

			{<FilteredRecipeLinkTable load={loadAssociations} />}
		</>
	);
}
