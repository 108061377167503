import { Button, Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../Common/Loader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import React from "react";
import { useGetRoomByIdQuery } from "../../../services/API/appAPI";

export default function Room() {
	const navigate = useNavigate();
	const { roomId = "" } = useParams();

	const { data: room = {}, isLoading } = useGetRoomByIdQuery(
		{ roomId },
		{ skip: !roomId }
	);

	console.log(room);

	const { name, description, mqttTopic, state, mode } = room;

	if (isLoading) return <Loader />;
	return (
		<Card variant='outlined' className='room card'>
			<CardContent className='room-content'>
				<div>
					<div>Name</div>
					<div>:</div>
					<div>{name}</div>
				</div>
				<div>
					<div>Description</div>
					<div>:</div>
					<div>{description}</div>
				</div>
				<div>
					<div>MQTT Topic</div>
					<div>:</div>
					<div>{mqttTopic}</div>
				</div>
				<div>
					<div>Active</div>
					<div>:</div>
					<div>{state}</div>
				</div>
				<div>
					<div>Mode</div>
					<div>:</div>
					<div>{mode}</div>
				</div>
				<div className='btns'>
					<Button
						variant='contained'
						className='btn'
						startIcon={<ModeEditIcon />}
						onClick={() => navigate("/rooms/" + roomId + "/edit")}>
						Edit
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}

/**{
    "name": "room2",
    "id": "room_63841165-5383-41e8-9e58-e1fb6c80cb30",
    "description": "desc2 + 123",
    "lastModified": "2024-02-13T17:55:36.967Z",
    "lastModifiedBy": "admin",
    "state": "active",
    "showEdit": true,
    "showDelete": true,
    "created": "2024-02-13T17:54:59.134Z",
    "createdBy": "admin",
    "mode": "live",
    "mqttTopic": "123456"
} */
