import styled from "@emotion/styled";

const Loader = styled.div`
	position: relative;
	width: 2.5em;
	height: 2.5em;
	transform: rotate(165deg);
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 0.5em;
		height: 0.5em;
		border-radius: 0.25em;
		transform: translate(-50%, -50%);
	}

	&:before {
		animation: before8 2s infinite;
	}

	&:after {
		animation: after6 2s infinite;
	}

	@keyframes before8 {
		0% {
			width: 0.5em;
			box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
				-1em 0.5em rgba(111, 202, 220, 0.75);
		}

		35% {
			width: 2.5em;
			box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
				0 0.5em rgba(111, 202, 220, 0.75);
		}

		70% {
			width: 0.5em;
			box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
				1em 0.5em rgba(111, 202, 220, 0.75);
		}

		100% {
			box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
				-1em 0.5em rgba(111, 202, 220, 0.75);
		}
	}

	@keyframes after6 {
		0% {
			height: 0.5em;
			box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
				-0.5em -1em rgba(233, 169, 32, 0.75);
		}

		35% {
			height: 2.5em;
			box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
				-0.5em 0 rgba(233, 169, 32, 0.75);
		}

		70% {
			height: 0.5em;
			box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
				-0.5em 1em rgba(233, 169, 32, 0.75);
		}

		100% {
			box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
				-0.5em -1em rgba(233, 169, 32, 0.75);
		}
	}

	& {
		position: absolute;
		z-index: 1112;
		top: calc(50% - 1.25em);
		left: calc(50% - 1.25em);
	}
`;

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1111;
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
	& > div {
		scale: 1.5;
		filter: brightness(1);
	}
`;

export default function Load({ open = true }) {
	if (open) {
		return (
			<Wrapper>
				<Loader />
			</Wrapper>
		);
	} else {
		return null;
	}
}
