import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	IconButton,
	InputAdornment,
	Link,
	OutlinedInput,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
	useGetSettingsMutation,
	useSetSettingsMutation,
} from "../../../services/API/appAPI";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import Loader from "../Common/Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "@emotion/styled";
import { successModal } from "../Common/Modal";
import { useNavigate } from "react-router-dom";

const StyledLink = styled(Link)`
	&:hover {
		cursor: pointer;
		color: darkblue !important; /* Change text color on hover */
	}
	color: #0000ffc2 !important; /* Set text color to blue */
	text-decoration: underline !important; /* Underline the text */
	cursor: pointer;
`;

const StyledCard = styled.div`
	.card:not(.btns) {
		margin-bottom: 1rem;
		padding: 1rem 15%;
	}
	.btns-wrap {
		display: flex;
		justify-content: center;
		.btns {
			display: flex;
			gap: 10px;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 2fr 100px;
		margin-bottom: 10px;
		align-items: center;
	}
	.row:not(.recipe) {
		grid-template-columns: 1fr 1fr;
	}
	.epex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 1rem;
	}
	.card > legend {
		font-weight: 500;
		padding: 1rem;
		background-color: #64748b;
		color: white;
		border-radius: 0.375rem;
		position: relative;
		top: 10px;
		left: -18%;
	}
`;

export default function Settings() {
	// const [settings, setSettings] = useState([]);
	const [
		getSettings,
		{ isLoading: loadSettings, isUninitialized, data: settings = [] },
	] = useGetSettingsMutation();
	const [updateSettings, { isLoading: loadUpdateSettings }] =
		useSetSettingsMutation();
	// const [isUpdating, setIsUpdating] = useState();
	const navigate = useNavigate();

	const [accordian, setAccordian] = useState({});
	const [viewPassword, setViewPassword] = useState({});

	// const isLoading = isUpdating;

	useEffect(() => {
		_getSettings();
	}, []);

	async function _getSettings() {
		// setIsUpdating(true);
		const { data = [] } = await getSettings();
		// if (data) setSettings(data);
		// setIsUpdating(false);
	}

	const epex = settings[0];
	const email = settings[1];
	const recipe = settings[2];
	const mqtt = settings[3];
	const system = settings[4];

	function getData(obj, formik) {
		if (!obj) return null;
		return (
			<Accordion defaultExpanded={accordian[obj.name]}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					onClick={() => {
						const current = { ...accordian };
						current[obj.name] = !current[obj.name];
						setAccordian(current);
					}}>
					<span className='heading'>{obj.name}</span>
				</AccordionSummary>
				<AccordionDetails>
					<Box key={obj.name}>
						{/* <Card variant='outlined' component={"fieldset"} className='card'> */}

						{Object.values(obj).map((values) => {
							if (typeof values == "object") {
								const {
									name,
									_key,
									password,
									values: options = [],
									readonly = false,
									multiline = false,
								} = values;
								if (password) {
									return (
										<>
											<div
												key={_key}
												className={
													obj.name == "Light Recipe Settings"
														? "recipe row"
														: "row"
												}>
												<div
													className={obj.name == "EPEX Settings" ? "epex" : ""}>
													<span>{name}</span>
													{/* {obj.name == "EPEX Settings" ? (
														<StyledLink
															onClick={() => {
																const body = {};
																body[_key] =
																	formik.values[_key.replace(/\./g, "_")];
																_updateSettings(body);
															}}>
															Force Update
														</StyledLink>
													) : null} */}
												</div>
												<OutlinedInput
													size='small'
													type={viewPassword[_key] ? "text" : "password"}
													multiline={multiline && viewPassword[_key]}
													maxRows={5}
													value={formik.values[_key.replace(/\./g, "_")]}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													name={_key.replace(/\./g, "_")}
													readOnly={readonly}
													endAdornment={
														<InputAdornment position='end'>
															<IconButton
																edge={"end"}
																onClick={() => {
																	const current = { ...viewPassword };
																	current[_key] = !current[_key];
																	setViewPassword(current);
																}}>
																{viewPassword[_key] ? (
																	<VisibilityOffIcon />
																) : (
																	<VisibilityIcon />
																)}
															</IconButton>
														</InputAdornment>
													}
												/>
											</div>
										</>
									);
								}
								if (options?.length > 0) {
									return (
										<div
											key={_key}
											className={
												obj.name == "Light Recipe Settings"
													? "recipe row"
													: "row"
											}>
											<div>{name}</div>
											{/* <TextField
									size='small'
									variant='outlined'
									value={formik.values[_key.replace(/\./g, "_")]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									name={_key.replace(/\./g, "_")}
								/> */}
											<Select
												value={formik.values[_key.replace(/\./g, "_")]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name={_key.replace(/\./g, "_")}
												inputProps={{ readOnly: readonly }}
												size='small'>
												{options.map((val) => (
													<MenuItem value={val}>{val}</MenuItem>
												))}
											</Select>
										</div>
									);
								}
								return (
									<div
										key={_key}
										className={
											obj.name == "Light Recipe Settings" ? "recipe row" : "row"
										}>
										<div>{name}</div>
										<OutlinedInput
											type='text'
											size='small'
											readOnly={readonly}
											value={formik.values[_key.replace(/\./g, "_")]}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name={_key.replace(/\./g, "_")}
											multiline={multiline}
											maxRows={5}
										/>
									</div>
								);
							} else return null;
						})}
						{/* </Card> */}
					</Box>
				</AccordionDetails>
			</Accordion>
		);
	}

	function getInitial() {
		const initial = {};

		settings.forEach((setting) => {
			Object.values(setting).forEach((obj) => {
				if (typeof obj == "object") {
					const { value, _key } = obj;
					initial[_key.replace(/\./g, "_")] = value;
				}
			});
		});
		return initial;
	}

	async function _updateSettings(Body) {
		// setIsUpdating(true);
		const { error } = await updateSettings({ body: Body });
		// setIsUpdating(false);
		if (error == undefined) {
			successModal({ message: "Settings updated successfully.!" });
		}
		_getSettings();
	}
	if (loadSettings || isUninitialized) return <Loader />;

	const initial = getInitial();

	return (
		<StyledCard variant='outlined'>
			<Loader open={loadUpdateSettings} />
			<Formik
				initialValues={initial}
				onSubmit={(values) => {
					async function update() {
						// setIsUpdating(true);
						const Body = {};
						for (const key of Object.keys(values)) {
							if (values[key] != initial[key])
								Body[key.replace(/\_/g, ".")] = values[key];
						}
						if (Object.keys(Body).length > 0) {
							_updateSettings(Body);
						}
					}
					update();
				}}>
				{(formik) => (
					<form onSubmit={formik.handleSubmit}>
						{getData(recipe, formik)}
						{getData(email, formik)}
						{getData(epex, formik)}
						{getData(mqtt, formik)}
						{getData(system, formik)}

						<Card variant='outlined' className='card btns-wrap'>
							<Box className=' btns'>
								<Button
									variant='contained'
									className='btn'
									onClick={() => navigate("/")}>
									Cancel
								</Button>
								<Button
									variant='contained'
									className='btn'
									onClick={() => {
										formik.resetForm();
									}}>
									Reset
								</Button>
								<Button
									variant='contained'
									className='btn'
									onClick={formik.handleSubmit}>
									Save Changes
								</Button>
							</Box>
						</Card>
					</form>
				)}
			</Formik>
		</StyledCard>
	);
}
