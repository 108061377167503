import { Grid, Stack } from "@mui/material";

import { Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";
import TopMenu from "./TopMenu";

// import MyModal from "./Pages/Common/Modal";

export default function Main() {
	return (
		<div className='page-container'>
			<div className='master-page-container'>
				<SideMenu className='left' />
				<div className='right'>
					<TopMenu />
					<div className='main-content-wrapper no-transition'>
						<Outlet />
						{/* <MyModal /> */}
					</div>
				</div>
			</div>
		</div>
	);
}
