import * as React from "react";

import {
	useGetUserByIdQuery,
	useResetUserPasswordMutation,
} from "../../../services/API/appAPI";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Loader from "../Common/Loader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { successModal } from "../Common/Modal";

//import TableHead from "@mui/material/TableHead";

export default function User() {
	const { userId } = useParams();
	const navigate = useNavigate();
	const [isUpdating, setIsUpdating] = React.useState(false);

	const { data, isLoading: loaduserbyid } = useGetUserByIdQuery(
		{ userId },
		{ skip: !userId }
	);

	const isLoading = isUpdating || loaduserbyid;

	const [resetPassword, {}] = useResetUserPasswordMutation();

	const user = data;

	if (!data) return null;

	const {
		username = "",
		firstName = "",
		lastName = "",
		email = "",
		roles = [],
		state = "",
		showResetPassword = false,
	} = user ?? {};

	async function sendResetPasswordRequest() {
		setIsUpdating(true);
		const { error } = resetPassword({ userId });
		setIsUpdating(false);
		if (error == undefined) {
			successModal({
				message: "Password reset successful (mail sent with details)",
			});
		}
	}

	if (isLoading) return <Loader />;

	return (
		<>
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/users")}>
				BACK
			</Button>
			<Card variant='outlined' className='user card'>
				<CardContent className='user-content'>
					<div className='row'>
						<div className='strong'>Login Name</div>
						<div>:</div>
						<div>{username}</div>
					</div>
					<div className='row'>
						<div className='strong'>Full Name</div>
						<div>:</div>
						<div>{firstName + lastName}</div>
					</div>
					<div className='row'>
						<div className='strong'>Email</div>
						<div>:</div>
						<div>{email}</div>
					</div>
					<div className='row'>
						<div className='strong'>Role</div>
						<div>:</div>
						<div>{roles.join(",")}</div>
					</div>
					<div className='row'>
						<div className='strong'>Status</div>
						<div>:</div>
						<div>{state == "active" ? "Active" : "Inactive"}</div>
					</div>
					<CardActions className='actions btns'>
						<Button
							variant='contained'
							className='btn'
							startIcon={<ModeEditIcon />}
							onClick={() => navigate("/users/" + userId + "/edit")}>
							Edit
						</Button>
						{showResetPassword ? (
							<Button
								variant='contained'
								className='btn'
								onClick={() => sendResetPasswordRequest()}>
								Reset Password
							</Button>
						) : null}
					</CardActions>
				</CardContent>
			</Card>
		</>
	);
}
