import * as Yup from "yup";

import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import { email as EmailReg, username as UserNameRegex } from "../Common/Regex";
import React, { useState } from "react";
import {
	useChangeUserPasswordMutation,
	useCheckEmailExistsMutation,
	useCheckUserNameExistsMutation,
	useCreateUserMutation,
	useGetRolesQuery,
	useGetUserByIdQuery,
	useUpdateUserMutation,
} from "../../../services/API/appAPI";
import { useNavigate, useParams } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Formik } from "formik";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { successModal } from "../Common/Modal";

//import TableHead from "@mui/material/TableHead";

export default function EditUser({ id, isProfile = false }) {
	const [passwordShow, setPasswordShow] = useState(false);
	const [reenterPasswordShow, setReenterPasswordShow] = useState(false);
	const { userId: _id } = useParams();
	const userId = _id ?? id;
	const navigate = useNavigate();
	const { data: userData, isLoading: loaduser } = useGetUserByIdQuery(
		{ userId },
		{ skip: !userId }
	);

	const [changePassword, {}] = useChangeUserPasswordMutation();

	const { data: rolesData, isLoading: loadroles } = useGetRolesQuery();
	// const [isUpdating, setIsUpdating] = useState(false);

	// const [updatedUserFormData, setUpdatedUserFormData] = useState(false);

	const [updateUser, { isLoading: loadUpdate }] = useUpdateUserMutation();

	const [createUser, { isLoading: loadCreate }] = useCreateUserMutation();

	const [checkEmail, { isLoading: loadCheckEmailExists }] =
		useCheckEmailExistsMutation();
	const [checkUserName, { isLoading: loadCheckNameExists }] =
		useCheckUserNameExistsMutation();

	const user = userData ?? {};

	const {
		username = "",
		firstName = "",
		lastName = "",
		email = "",
		state = "",
		roles = [""],
	} = user;
	const userFormData = {
		username,
		firstName,
		lastName,
		email,
		active: state == "active",
		role: roles[0],
		password: "",
		reenterpassword: "",
	};

	async function _changePassword(password) {
		return changePassword({ userId: id, body: { password } });
	}

	const schema = Yup.object().shape({
		username: Yup.string()
			.matches(UserNameRegex, "Invalid Login Name")
			.required("Login Name is required*"),
		email: Yup.string()
			.matches(EmailReg, "Invalid Email Address")
			.required("Email is required*"),
		role: Yup.string().required("Role is required*"),
		password: Yup.string().matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			"Invalid Password"
		),
		reenterpassword: Yup.string()
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Invalid Password"
			)
			.oneOf([Yup.ref("password")], "Passwords must match"),
	});

	if (loadroles || (loaduser && userId)) return <Loader />;

	return (
		<>
			<Loader
				open={
					loadCheckEmailExists ||
					loadCheckNameExists ||
					loadUpdate ||
					loadCreate
				}
			/>
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/users")}>
				BACK
			</Button>
			{/* <h3>{user.id ? "Edit User" : "Create User"}</h3> */}
			<Formik
				initialValues={userFormData}
				validationSchema={schema}
				onSubmit={(values, actions) => {
					async function formSubmit() {
						// setIsUpdating(true);
						var { error } = {};
						if (userId) {
							var { error } = await updateUser({
								body: {
									username: values.username,
									firstName: values.firstName,
									lastName: values.lastName,
									email: values.email,
									state: values.active ? "active" : "inactive",
									roles: [values.role],
								},
								userId,
							});
						} else {
							var { error } = await createUser({
								body: {
									username: values.username,
									firstName: values.firstName,
									lastName: values.lastName,
									email: values.email,
									state: "active",
									roles: [values.role],
								},
							});
						}
						if (!error) {
							if (values.password && isProfile) {
								await _changePassword(values.password);
							}
							successModal({
								message: userId
									? `User: ${values?.username} updated successfully.`
									: `User: ${values?.username} created successfully.`,
							}).then(() => {
								if (!isProfile) navigate("/users");
							});
							// setUpdatedUserFormData({ ...values });
						}
						// setIsUpdating(false);
					}
					formSubmit();
				}}>
				{(formik) => {
					return (
						<Card
							variant='outlined'
							className='user-edit card'
							sx={{ width: "fit-content", minWidth: "40%", marginTop: "1rem" }}>
							<CardContent component={Stack} gap={3}>
								<>
									<div className='user-form-row'>
										<TextField
											value={formik.values.username}
											onChange={formik.handleChange}
											onBlur={(event) => {
												event.preventDefault();
												formik.handleBlur(event);
												async function check() {
													// setUpdatedUserFormData(formik.values);
													// setIsUpdating(true);
													const val = event?.target?.value ?? "";
													if (val) {
														const { data } = await checkUserName({
															username: val,
														});
														const { exists = false } = data ?? {};
														if (exists) {
															formik.setFieldError(
																"username",
																"Login Name already exists"
															);
															formik.validateField(false);
														}
													}
													console.log(formik);
												}
												check();
											}}
											name='username'
											size='small'
											fullWidth
											label='Login Name'
											aria-describedby='login-name-label'
											disabled={!!userId || isProfile}
										/>
										{formik.touched?.username && formik.errors.username ? (
											<span className='user-form-error'>
												{formik.errors.username}
											</span>
										) : null}
									</div>
								</>
								<>
									<div className='user-form-row'>
										<TextField
											value={formik.values.firstName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='firstName'
											fullWidth
											label='First Name'
											size='small'
											aria-describedby='full-name-label'
										/>
										{formik.touched?.firstName && formik.errors.firstName ? (
											<span className='user-form-error'>
												{formik.errors.firstName}
											</span>
										) : null}
									</div>
								</>
								<>
									<div className='user-form-row'>
										<TextField
											value={formik.values.lastName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='lastName'
											fullWidth
											label='Last Name'
											size='small'
											aria-describedby='full-name-label'
										/>
										{formik.touched?.lastName && formik.errors.lastName ? (
											<span className='user-form-error'>
												{formik.errors.lastName}
											</span>
										) : null}
									</div>
								</>
								<>
									<div className='user-form-row'>
										<TextField
											value={formik.values.email}
											onChange={formik.handleChange}
											disabled={isProfile}
											onBlur={(event) => {
												event.preventDefault();
												formik.handleBlur(event);
												async function check() {
													// setUpdatedUserFormData(formik.values);
													// setIsUpdating(true);
													const val = event?.target?.value ?? "";
													if (val) {
														const { data } = await checkEmail({
															email: val,
														});
														const { exists = false } = data ?? {};
														if (exists) {
															formik.setFieldError(
																"email",
																"Email already exists"
															);
														}
													}
													// setIsUpdating(false);
													console.log(formik);
												}
												check();
											}}
											name='email'
											fullWidth
											label='Email-address'
											aria-describedby='my-helper-text'
											size='small'
										/>
										{formik.touched?.email && formik.errors.email ? (
											<span className='user-form-error'>
												{formik.errors.email}
											</span>
										) : null}
									</div>
								</>
								<>
									<div className='user-form-row'>
										<FormControl className='input' fullWidth>
											<InputLabel id='simple-select-helper-label' size='small'>
												Role
											</InputLabel>
											<Select
												labelId='simple-select-helper-label'
												value={formik.values.role}
												name='role'
												label='Age'
												size='small'
												disabled={isProfile}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}>
												<MenuItem value={"admin"}>{"admin"}</MenuItem>
												{rolesData?.roles?.map(({ name }) => (
													<MenuItem value={name}>{name}</MenuItem>
												))}
											</Select>
											{formik.touched?.role && formik.errors.role ? (
												<span className='error select'>
													{formik.errors.role}
												</span>
											) : null}
										</FormControl>
									</div>
								</>
								{userId ? (
									<>
										<div className='user-form-row'>
											<FormControlLabel
												control={
													<Checkbox
														checked={formik.values.active}
														name='active'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size='small'
														disabled={isProfile}
													/>
												}
												label={"Active"}
											/>
										</div>
									</>
								) : null}
								{isProfile && (
									<div className='user-form-row'>
										<TextField
											fullWidth
											size='small'
											type={passwordShow ? "text" : "password"}
											value={formik.values["password"]}
											onChange={formik.handleChange}
											label='Password'
											onBlur={formik.handleBlur}
											name={"password"}
											// readOnly={readonly}

											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															edge={"end"}
															onClick={() => {
																setPasswordShow((s) => !s);
															}}>
															{passwordShow ? (
																<VisibilityOffIcon />
															) : (
																<VisibilityIcon />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										{formik.touched?.password && formik.errors.password ? (
											<span className='error select'>
												{formik.errors.password}
											</span>
										) : null}
									</div>
								)}
								{isProfile && (
									<div className='user-form-row'>
										<TextField
											fullWidth
											size='small'
											type={reenterPasswordShow ? "text" : "password"}
											value={formik.values["reenterpassword"]}
											onChange={formik.handleChange}
											label='Re-enter Password'
											onBlur={formik.handleBlur}
											name={"reenterpassword"}
											// readOnly={readonly}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															edge={"end"}
															onClick={() => {
																setReenterPasswordShow((s) => !s);
															}}>
															{reenterPasswordShow ? (
																<VisibilityOffIcon />
															) : (
																<VisibilityIcon />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										{formik.touched?.reenterpassword &&
										formik.errors.reenterpassword ? (
											<span className='error select'>
												{formik.errors.reenterpassword}
											</span>
										) : null}
									</div>
								)}
								<div className='user-form-row'>
									<CardActions className='actions'>
										<Button
											variant='contained'
											className='btn'
											type='submit'
											startIcon={<KeyboardArrowUpIcon />}
											onClick={formik.handleSubmit}>
											SUBMIT
										</Button>
										{isProfile ? null : (
											<Button
												variant='contained'
												component={Link}
												to={"/users"}
												className='btn'
												startIcon={<CancelIcon />}>
												CANCEL
											</Button>
										)}
									</CardActions>
								</div>
							</CardContent>
						</Card>
					);
				}}
			</Formik>
		</>
	);
}
