import { Button, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../Common/Loader";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ResourcePermissionTable from "./ResourcePermissionTable";
import { appAPI } from "../../../services/API/appAPI";
import { useDispatch } from "react-redux";

export default function ViewRole() {
	const { roleId } = useParams();
	const [isUpdating, setIsUpdating] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [role, setRole] = useState({});

	useEffect(() => {
		fetchRole();
	}, []);

	async function fetchRole() {
		if (roleId?.length > 0) {
			setIsUpdating(true);
			const { data } = await dispatch(
				appAPI.endpoints.getRoleById.initiate(
					{ roleId },
					{ forceRefetch: true }
				)
			);
			if (data) setRole(data);
			setIsUpdating(false);
		}
	}
	if (isUpdating) return <Loader />;
	const { name, description, users = [] } = role;
	return (
		<>
			<Card variant='outlined' className='role card'>
				<CardContent className='role-content'>
					<div>
						<div>Name</div>
						<div>:</div>
						<div>{name}</div>
					</div>
					<div>
						<div>Description</div>
						<div>:</div>
						<div>{description}</div>
					</div>
					<div>
						<div>Users</div>
						<div>:</div>
						<div>
							{users?.map(({ username = "" }) => username)?.join(" , ") ?? ""}
						</div>
					</div>
					<div className='btns'>
						<Button
							variant='contained'
							className='btn'
							startIcon={<ModeEditIcon />}
							onClick={() => navigate("/roles/" + roleId + "/edit")}>
							Edit
						</Button>
					</div>
				</CardContent>
			</Card>
			<ResourcePermissionTable
				data={role?.allowed ?? []}
				resources={{}}
				hideDelete={true}
			/>
		</>
	);
}
