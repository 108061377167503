import Swal from "sweetalert2";

const Title = "Recipe Manager";

const commonSettings = { allowOutsideClick: false, showCloseButton: true };

function getMessage(msg) {
	if (Array.isArray(msg)) {
		return msg.reduce(
			(acc, curr) =>
				acc + `<div style="margin:5px 0;text-align: left">${curr}</div>`,
			""
		);
	} else {
		return `<div>${msg}</div>`;
	}
}

export const deleteModal = ({ title = "" }) => {
	return Swal.fire({
		html: `<div><div class='sub-title'>${title}</div><div> Are you sure? </div></div>`,
		icon: "warning",
		showCancelButton: true,
		title: Title,
		confirmButtonColor: "#d33",
		confirmButtonText: "Delete",
		cancelButtonText: "Cancel",
		reverseButtons: true,
		customClass: {
			// container: "...",
			// popup: "...",
			// header: "...",
			// title: "...",
			// closeButton: "...",
			// icon: "...",
			// image: "...",
			// htmlContainer: "...",
			// input: "...",
			// inputLabel: "...",
			// validationMessage: "...",
			// actions: "...",
			confirmButton: "delete-confirm",
			// denyButton: "...",
			// cancelButton: "...",
			// loader: "...",
			// footer: "....",
			// timerProgressBar: "....",
		},
		focusCancel: true,
		...commonSettings,
	});
};

export const successModal = ({ message = "" }) => {
	return Swal.fire({
		html: `<div>${message}</div>`,
		icon: "success",
		// showCancelButton: true,
		title: Title,
		// focusCancel: true,
		...commonSettings,
	});
};

export const questionModal = ({ message = "" }) => {
	return Swal.fire({
		html: `${getMessage(message)}`,
		icon: "question",
		showCancelButton: true,
		title: Title,
		confirmButtonText: "Activate",
		...commonSettings,
	});
};

export const errorModal = ({ message = "" }) => {
	return Swal.fire({
		html: `<div>${message}</div>`,
		icon: "error",
		title: Title,
		...commonSettings,
	});
};
