import "./styles/main.css";
import "./styles/main.scss";

import { createContext, useEffect, useState } from "react";

import MasterPage from "./MasterPage";
import { ThemeProvider } from "@emotion/react";
import muiTheme from "./styles/theme";
import { useNavigate } from "react-router-dom";

export const ModalContext = createContext();

const modalInitialValue = {
	display: false,
	navigate: "",
	deleteWarning: {
		proceed: false,
		show: false,
	},
};

export const ThemeContext = createContext();

function App() {
	const [theme, setTheme] = useState("theme1");
	// const [modalData, setModalData] = useState(modalInitialValue);
	// const navigate = useNavigate();

	// const open = () => {
	// 	setModalData((obj) => ({ ...obj, display: true }));
	// };

	// const close = () => {
	// 	setModalData((obj) => ({
	// 		...obj,
	// 		deleteWarning: { ...obj.deleteWarning, show: false },
	// 		display: false,
	// 	}));
	// 	if (modalData.navigate.length > 0)
	// 		navigate(modalData.navigate, { state: { key: "val" } });
	// };

	// const setBody = (body) => {
	// 	setModalData((obj) => ({ ...obj, Body: body }));
	// };

	// const setNavigate = (str) => {
	// 	setModalData((obj) => ({ ...obj, navigate: str }));
	// };

	// const showDeleteWarning = (id) => {
	// 	setModalData((obj) => ({
	// 		...obj,
	// 		deleteWarning: { ...obj.deleteWarning, proceed: false, show: true, id },
	// 		display: true,
	// 	}));
	// };

	// const setProceedDelete = (proceed) => {
	// 	setModalData((obj) => ({
	// 		...obj,
	// 		deleteWarning: { ...obj.deleteWarning, proceed },
	// 		display: false,
	// 	}));
	// };

	// const resetDeleteWarning = () => {
	// 	setModalData((obj) => ({
	// 		...obj,
	// 		deleteWarning: { ...obj.deleteWarning, proceed: false, show: false },
	// 		display: false,
	// 	}));
	// };

	// useEffect(() => {
	// 	console.log("modal data", modalData);
	// }, [modalData]);

	return (
		<div className={"App " + theme}>
			<ThemeProvider theme={muiTheme}>
				<ThemeContext.Provider value={theme}>
					{/* <ModalContext.Provider
						value={{
							data: modalData,
							setBody,
							open,
							close,
							setNavigate,
							showDeleteWarning,
							resetDeleteWarning,
							setProceedDelete,
						}}> */}
					<MasterPage />
					{/* </ModalContext.Provider> */}
				</ThemeContext.Provider>
			</ThemeProvider>
		</div>
	);
}

export default App;
