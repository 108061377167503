import * as Yup from "yup";

import {
	Button,
	Checkbox,
	FormControl,
	InputLabel,
	OutlinedInput,
	Select,
	Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ResourcePermissionTable, {
	getItemsName,
} from "./ResourcePermissionTable";
import {
	appAPI,
	useAddPermissionToRoleMutation,
	useCheckRoleNameExistsMutation,
	useCreateRoleMutation,
	useDeleteRuleMutation,
	useGetResourcesByTypeMutation,
	useGetResourcesQuery,
	useLazyGetRoleByIdQuery,
	useUpdateRoleMutation,
} from "../../../services/API/appAPI";
import { deleteModal, successModal } from "../Common/Modal";
import { useNavigate, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Formik } from "formik";
import ListItemText from "@mui/material/ListItemText";
import Loader from "../Common/Loader";
import MenuItem from "@mui/material/MenuItem";
import { username as UserNameRegex } from "../Common/Regex";

// import { useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function EditRole() {
	const { roleId } = useParams();
	const [checkRoleName, { isLoading: loadCheckNameExists }] =
		useCheckRoleNameExistsMutation();

	// const [isUpdating, setIsUpdating] = useState(false);

	const { data: resourceTypes = [], isLoading: loadresourses } =
		useGetResourcesQuery({}, { skip: !roleId });

	const [curResourceType, setCurResourceType] = useState(
		resourceTypes && resourceTypes[0]
	);

	// const [role, setRole] = useState({});
	// const { data: role, isLoading: loadROlesById } = useGetRoleByIdQuery(
	// 	{ roleId },
	// 	{ skip: !roleId }
	// );

	// const [updatedRole, setUpdatedRole] = useState(false);

	const [createRole, { isLoading: loadCreateRole }] = useCreateRoleMutation();
	const [updateRole, { isLoading: loadUpdateRole }] = useUpdateRoleMutation();
	const [deleteRule, { isLoading: loadDeleteRule }] = useDeleteRuleMutation();
	const [addPermissionToRole, { isLoading: loadAddPermissionToRole }] =
		useAddPermissionToRoleMutation();
	const [getResourcesByType, { isLoading: loadGetResourcesByType }] =
		useGetResourcesByTypeMutation();
	const [
		getRoleById,
		{
			currentData: role,
			isLoading: loadGetRoleById,
			isUninitialized,
			isFetching: fetchGetRoleById,
		},
	] = useLazyGetRoleByIdQuery();

	const isNewRole = roleId?.length > 0 ? false : true;

	const { name = "", description = "" } = role ?? {};

	const [allResources, setAllResources] = useState({});

	const navigate = useNavigate();
	// const dispatch = useDispatch();

	async function fetchRole() {
		if (!isNewRole) {
			// setIsUpdating(true);
			await getRoleById({ roleId });
			// if (data) setRole(data);
			// setIsUpdating(false);
		}
	}

	useEffect(() => {
		fetchRole();
	}, []);

	async function _deleteRule(rule) {
		const permissions = [];
		if (rule?.read) permissions.push("read");
		if (rule?.create) permissions.push("create");
		if (rule?.edit) permissions.push("edit");
		if (rule?.delete) permissions.push("delete");
		const { isConfirmed } = await deleteModal({
			title: `Remove permission for ${rule?.resourceType ?? ""}: ${getItemsName(
				rule?.resource,
				rule?.data
			)} ${permissions?.join(",")}`,
		});
		if (isConfirmed) {
			// setIsUpdating(true);
			const { error } = !isNewRole
				? await deleteRule({ roleId, ruleId: rule?.ruleId })
				: {};
			// setIsUpdating(false);
			if (error == undefined) {
				await fetchRole();
				successModal({
					message: `Permission for ${rule?.resourceType ?? ""}: ${getItemsName(
						rule?.resource,
						rule?.data
					)} ${permissions?.join(",")} deleted successfully.`,
				});
			}
		}
	}

	async function fetchResourcesByType(type) {
		try {
			if (allResources[type]) return;
			// setIsUpdating(true);
			const { data } = await getResourcesByType({ resourceType: type });
			if (data) {
				const obj = { ...allResources };
				obj[type] = data;
				setAllResources(obj);
			}
		} catch (error) {
		} finally {
			// setIsUpdating(false);
			console.log(allResources);
		}
	}

	const availableResourceTypes = resourceTypes.filter((type) => {
		const totalResources = allResources[type]?.length ?? 0;
		const allowed = role?.allowed ?? [];
		const doneResources = allowed.reduce(
			(acc, cur) => (cur.resourceType == type ? acc + 1 : acc),
			0
		);
		const x = allowed.some(
			({ resourceType, resource }) => resourceType == type && resource == "*"
		);
		if (x) return false;
		return totalResources > doneResources;
	});
	if (
		loadresourses ||
		loadGetResourcesByType ||
		loadGetRoleById ||
		(isUninitialized && roleId) ||
		fetchGetRoleById
	)
		return <Loader />;

	const isRoleIdPresent = roleId?.length > 0;

	return (
		<>
			<Loader
				open={
					loadCreateRole ||
					loadAddPermissionToRole ||
					loadCheckNameExists ||
					loadDeleteRule ||
					loadUpdateRole
				}
			/>
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/roles")}>
				BACK
			</Button>
			<Stack
				direction={{ xs: "column", sm: "row" }}
				justifyContent={"space-between"}
				sx={{ position: "relative" }}>
				<Card
					variant='outlined'
					className='role-edit card'
					sx={{ width: "45%" }}>
					<Formik
						initialValues={{ name, description }}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.matches(UserNameRegex, "Invalid User Name")
								.required("Name is required*"),
							description: Yup.string().required("Description is required*"),
						})}
						onSubmit={(values) => {
							async function formSubmit() {
								try {
									// setIsUpdating(true);
									if (!isNewRole) {
										const { error } = await updateRole({
											body: {
												name: values.name,
												description: values.description,
												state: role.state,
											},
											roleId,
										});
										if (error == undefined) {
											successModal({
												message: `Role: ${values?.name} updated successfully`,
											});
											// setUpdatedRole({ ...values });
										}
									} else {
										const { data, error } = await createRole({
											body: {
												name: values.name,
												description: values.description,
											},
										});
										if (!error) {
											successModal({
												message: `Role: ${values?.name} created successfully`,
											});
											// setUpdatedRole({ ...values });
										}
										if (data?.roleId) {
											navigate("/roles/" + data.roleId + "/edit");
										}
									}
								} catch (error) {
								} finally {
									// setIsUpdating(false);
								}
							}
							formSubmit();
						}}>
						{(formik) => (
							<CardContent>
								<table aria-label='role edit' style={{ width: "100%" }}>
									<tbody>
										<tr className='row'>
											<td>
												<FormControl
													className='input'
													fullWidth
													style={{ position: "relative" }}
													disabled={!!roleId}>
													<InputLabel
														id='role-name-label'
														htmlFor='outlined-adornment-role-name'
														size='small'>
														Role Name
													</InputLabel>
													<OutlinedInput
														id='outlined-adornment-role-name'
														type={"text"}
														label='Name'
														size='small'
														value={formik.values.name}
														onChange={formik.handleChange}
														onBlur={(event) => {
															event.preventDefault();
															formik.handleBlur(event);
															async function check() {
																// setUpdatedRole(formik.values);
																// setIsUpdating(true);
																const val = event?.target?.value ?? "";
																if (val) {
																	const { data } = await checkRoleName({
																		roleName: val,
																	});
																	const { exists = false } = data ?? {};
																	if (exists) {
																		formik.setFieldError(
																			"name",
																			"name already exists"
																		);
																	}
																}
																// setIsUpdating(false);
															}
															check();
														}}
														name='name'
														aria-describedby='role-name-label'
													/>
													{formik.touched?.name && formik.errors.name ? (
														<span className='error'>{formik.errors.name}</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										<tr className='row'>
											<td>
												<FormControl
													className='input'
													fullWidth
													style={{ position: "relative" }}>
													<InputLabel
														id='role-description-label'
														htmlFor='outlined-adornment-role-description'
														size='small'>
														Role Description
													</InputLabel>
													<OutlinedInput
														value={formik.values.description}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='description'
														id='outlined-adornment-role-description'
														type={"text"}
														label='Description'
														size='small'
														aria-describedby='role-description-label'
													/>
													{formik.touched?.description &&
													formik.errors.description ? (
														<span className='error'>
															{formik.errors.description}
														</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										{/* {!isNewRole ? (
											<tr className='row'>
												<td>
													<FormControl
														className='input'
														fullWidth
														style={{ position: "relative" }}>
														<InputLabel
															id='role-users-label'
															htmlFor='outlined-adornment-role-users'
															size='small'>
															Users
														</InputLabel>
														<OutlinedInput
															value={formik.values.users}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name='users'
															id='outlined-adornment-role-users'
															type={"text"}
															label='Users'
															size='small'
															aria-describedby='role-users-label'
														/>
														{formik.touched?.users && formik.errors.users ? (
															<span className='error'>
																{formik.errors.users}
															</span>
														) : null}
													</FormControl>
												</td>
											</tr>
										) : null} */}
									</tbody>
								</table>
								<CardActions
									className='actions role-creation'
									sx={
										isRoleIdPresent
											? { position: "relative", bottom: "-3rem" }
											: {}
									}>
									<Button
										variant='contained'
										className='btn'
										onClick={formik.handleSubmit}>
										{!!roleId ? "UPDATE" : "CREATE"}
									</Button>
								</CardActions>
							</CardContent>
						)}
					</Formik>
				</Card>

				{isRoleIdPresent ? (
					<Card
						variant='outlined'
						className='role-edit card'
						sx={{ width: "45%" }}>
						<Formik
							initialValues={{
								resourceType: curResourceType,
								resources: [],
								// permissions: ["view", "create", "update", "delete"],
								view: false,
								create: false,
								update: false,
								delete: false,
								all: true,
							}}
							validationSchema={Yup.object().shape({
								resourceType: Yup.string().required(
									"Resource Type is Required*"
								),
								// resources: Yup.array().min(1, "At least one item is required"),
							})}
							onSubmit={(values) => {
								async function formSubmit() {
									try {
										// setIsUpdating(true);
										if (!!roleId) {
											let rss = values.resources.map((curname) => {
												const ret = {};
												ret.id = allResources[values.resourceType].find(
													({ username, name }) => {
														if (values.resourceType == "user")
															return username == curname;
														else return name == curname;
													}
												)?.id;
												ret.data = {};
												ret.data.name = curname;
												return ret;
											});
											const permissions = [];
											if (values.all) {
												rss = ["*"];
												if (values.create) permissions.push("create");
											}
											if (values.view) permissions.push("read");
											if (values.update) permissions.push("edit");
											if (values.delete) permissions.push("delete");

											const body = {
												resources: rss,
												resourceType: values.resourceType,
												permissions,
											};
											const { error } = await addPermissionToRole({
												body,
												roleId,
											});
											if (error == undefined) {
												await fetchRole();
												successModal({
													message: "Permission(s) added successfully.",
												});
											}
										}
									} catch (error) {
									} finally {
										// setIsUpdating(false);
									}
								}
								formSubmit();
							}}>
							{(formik) => (
								<CardContent>
									<table aria-label='role edit' style={{ minWidth: "400px" }}>
										<tbody>
											<tr className='row'>
												<td>
													<Stack direction='row' spacing={2}>
														<FormControl
															sx={{ m: 1, minWidth: 200 }}
															size='small'
															className='drop-down'
															style={{ display: "flex" }}
															fullWidth>
															<InputLabel id='resouce-selector'>
																Resource
															</InputLabel>
															<Select
																labelId='resouce-selector'
																id='select-small'
																value={formik.values.resourceType}
																onChange={(event) => {
																	formik.handleChange(event);
																	formik.setFieldValue("resources", []);
																	setCurResourceType(event.target.value);
																	fetchResourcesByType(event.target.value);
																}}
																onBlur={formik.handleBlur}
																name='resourceType'
																label='Resource'>
																{resourceTypes.map((resource) => (
																	<MenuItem value={resource} key={resource}>
																		{resource}
																	</MenuItem>
																))}
															</Select>
														</FormControl>

														<Stack
															direction='row'
															justifyContent={"center"}
															alignItems={"center"}>
															<Checkbox
																{...{
																	inputProps: {
																		"aria-label": "Checkbox Active",
																	},
																}}
																checked={formik.values.all}
																onChange={(event) => {
																	formik.handleChange(event);
																}}
																onBlur={formik.handleBlur}
																name='all'
															/>
															<span>ALL</span>
														</Stack>
													</Stack>
												</td>
											</tr>
											<tr className='row'>
												<td style={{ paddingRight: "85px" }}>
													<FormControl
														sx={{ m: 1, minWidth: 200 }}
														size='small'
														className='drop-down'
														fullWidth
														disabled={formik.values.all}>
														<InputLabel id='list-multiple-selector'>
															Resource Items
														</InputLabel>
														<Select
															labelId='list-multiple-selector'
															id='multiple-checkbox'
															multiple
															value={formik.values.resources}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name='resources'
															input={<OutlinedInput label='List' />}
															renderValue={(selected) => {
																return selected.join(", ");
															}}
															MenuProps={MenuProps}>
															{allResources[formik.values.resourceType]
																?.filter(
																	({ id }) =>
																		!role.allowed?.some(
																			({ resource }) => id == resource
																		)
																)
																.map((resource) => {
																	const Val =
																		formik.values.resourceType == "user"
																			? resource.username
																			: resource.name;
																	return (
																		<MenuItem key={resource.id} value={Val}>
																			<Checkbox
																				checked={
																					formik.values.resources.indexOf(Val) >
																					-1
																				}
																			/>
																			<ListItemText primary={Val} />
																		</MenuItem>
																	);
																})}
														</Select>
													</FormControl>
												</td>
											</tr>
										</tbody>
									</table>

									<Stack direction='row' spacing={2}>
										<div>
											<Checkbox
												{...{
													inputProps: { "aria-label": "Checkbox Active " },
												}}
												value={formik.values.view}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='view'
											/>
											VIEW
										</div>
										<div>
											<Checkbox
												{...{ inputProps: { "aria-label": "Checkbox Active" } }}
												value={formik.values.create}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='create'
												disabled={!formik.values.all}
											/>
											CREATE
										</div>
										<div>
											<Checkbox
												{...{ inputProps: { "aria-label": "Checkbox Active" } }}
												value={formik.values.update}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='update'
											/>
											UPDATE
										</div>
										<div>
											<Checkbox
												{...{ inputProps: { "aria-label": "Checkbox Active" } }}
												value={formik.values.delete}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='delete'
											/>
											DELETE
										</div>
									</Stack>

									<CardActions className='actions'>
										<Button
											variant='contained'
											className='btn'
											disabled={!roleId}
											onClick={formik.handleSubmit}>
											ADD PERMISSION
										</Button>
									</CardActions>
								</CardContent>
							)}
						</Formik>
					</Card>
				) : null}
			</Stack>

			{!isNewRole ? (
				<ResourcePermissionTable
					data={role?.allowed ?? []}
					onDelete={_deleteRule}
				/>
			) : null}
		</>
	);
}
