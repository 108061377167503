import * as React from "react";

import { InputLabel, MenuItem, Pagination, Select, Stack } from "@mui/material";

export default function usePagination(noOfEntries) {
	const defaultPerPage = 20;
	const [currentPage, SetCurrentPage] = React.useState(1);
	const [_max, _setMax] = React.useState(
		Math.ceil(noOfEntries / defaultPerPage)
	);
	const [entriesPerPage, setEntriesPerPage] = React.useState(defaultPerPage);

	const [total, setTotal] = React.useState(noOfEntries);

	const setTotalValue = (val) => {
		setTotal(Number(val));
		_setMax(Math.ceil(Number(val) / Number(entriesPerPage)));
	};

	const render = () => {
		const entriesPerPageArray = [10, 20, 50, 100];
		const start = entriesPerPage * (currentPage - 1) + 1;
		const end =
			start + entriesPerPage - 1 <= total ? start + entriesPerPage - 1 : total;

		return (
			<Stack
				className='pagination'
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={{ xs: 1, sm: 1.5 }}>
				<Stack direction={"row"} spacing={1} alignItems='center'>
					<Select
						labelId='select-page-per-value'
						value={entriesPerPage}
						onChange={(e) => {
							const val = Number(e.target.value);
							_setMax(Math.ceil(total / val));
							setEntriesPerPage(val);
							SetCurrentPage(1);
						}}
						size='small'
						className='select pagination-drop-down'>
						{entriesPerPageArray.map((val) => (
							<MenuItem value={val} key={val}>
								{val}
							</MenuItem>
						))}
					</Select>
					<InputLabel id='select-page-per-value'>entries per page</InputLabel>
				</Stack>
				<Pagination
					defaultPage={currentPage}
					onChange={(event, newPage) => {
						SetCurrentPage(newPage);
					}}
					count={_max}
					color='primary'
					siblingCount={2}
					boundaryCount={2}
				/>
			</Stack>
		);
	};
	return [currentPage, entriesPerPage, render, setTotalValue];
}

{
	/* <div>
					{start == end
						? "showing " + start + "th entry "
						: `showing ${start} to ${end} `}
					of {total} entries
				</div> */
}
{
	/* <Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={{ xs: 1, sm: 1.5 }}>
				{/** add actionable to buttons and rows of the table
				<span
					className='left-btn'
					style={{ visibility: currentPage == 1 ? "hidden" : "visible" }}
					onClick={() => {
						if (currentPage == 1) return;
						const cur = Number(currentPage);
						if (cur > 1) SetCurrentPage(cur - 1);
					}}>
					<ChevronLeftIcon fontSize='small' />
				</span>
				<input
					aria-invalid='false'
					type='number'
					min='1'
					max={_max}
					value={currentPage}
					onChange={(e) => {
						const val = Number(e.target.value);
						if (val > 0 && val <= _max) SetCurrentPage(e.target.value);
					}}
				/>
				<span
					className='right-btn'
					style={{
						visibility:
							currentPage == _max || _max == 0 ? "hidden" : "visible",
					}}
					onClick={() => {
						if (currentPage == _max) return;
						const cur = Number(currentPage);
						if (cur < _max) SetCurrentPage(cur + 1);
					}}>
					<ChevronRightIcon fontSize='small' />
				</span>
			</Stack> */
}
