import * as Yup from "yup";

import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	OutlinedInput,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import {
	useCreateRoomMutation,
	useGetRoomByIdQuery,
	useLazyCheckRoomNameExistsQuery,
	useUpdateRoomMutation,
} from "../../../services/API/appAPI";
import { useNavigate, useParams } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Formik } from "formik";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";
import { username as UserNameRegex } from "../Common/Regex";
import { successModal } from "../Common/Modal";

export default function EditRoom() {
	const [isUpdating, setIsUpdating] = useState();
	const [updatedRoom, setUpdatedRoom] = useState(false);
	const navigate = useNavigate();
	const { roomId = "" } = useParams();
	const [
		checkRoomNameExists,
		{ isLoading: loadCheckRoomName, isFetching: fetchCheckRoomName },
	] = useLazyCheckRoomNameExistsQuery();

	const {
		data: room = {},
		isLoading: loadRoom,
		isFetching,
		isUninitialized,
	} = useGetRoomByIdQuery({ roomId }, { skip: !roomId });

	const isLoading = isUpdating || loadRoom;

	const [createRoom, {}] = useCreateRoomMutation();
	const [updateRoom, {}] = useUpdateRoomMutation();

	const isEditMode = roomId?.length > 0;

	const {
		name = "",
		description = "",
		mqttTopic = "",
		mode = "trial",
		state = "",
	} = room;

	const initialval = {
		name,
		description,
		mqttTopic,
		mode,
		active: state == "active",
	};

	if ((isUninitialized && roomId) || isFetching) return <Loader />;
	return (
		<>
			<Loader open={isLoading || loadCheckRoomName || fetchCheckRoomName} />
			<Button
				variant='contained'
				className='btn'
				startIcon={<ChevronLeftIcon />}
				onClick={() => navigate("/rooms")}>
				BACK
			</Button>
			{/* <h3>{false ? "Edit Room" : "Create Room"}</h3> */}
			<Formik
				initialValues={updatedRoom ? updatedRoom : initialval}
				validationSchema={Yup.object().shape({
					name: Yup.string()
						.matches(UserNameRegex, "Invalid Name")
						.required("Name is required*"),
					description: Yup.string().required("Description is required*"),
					mode: Yup.string().required("Mode is required*"),
					mqttTopic: Yup.string().required("MQTT Topic is required*"),
				})}
				onSubmit={(values) => {
					async function submit() {
						if (isEditMode) {
							setIsUpdating(true);
							const { name, description, mqttTopic, mode, active } = values;
							const { error } = await updateRoom({
								body: {
									name,
									description,
									mqttTopic,
									mode,
									state: active ? "active" : "inactive",
								},
								roomId,
							});
							setIsUpdating(false);
							if (error == undefined) {
								successModal({
									message: `Room: ${name} updated successfully.`,
								}).then(() => {
									navigate("/rooms");
									setUpdatedRoom({ ...values });
								});
							}
						} else {
							setIsUpdating(true);
							const { name, description, mqttTopic, mode, active } = values;
							const { error } = await createRoom({
								body: { name, description, mqttTopic, mode },
							});
							setIsUpdating(false);
							if (error == undefined) {
								successModal({
									message: `Room: ${name} created successfully.`,
								}).then(() => {
									navigate("/rooms");
								});
							}
						}
					}
					submit();
				}}>
				{(formik) => (
					<Card variant='outlined' className='room-edit card'>
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<table aria-label='room edit'>
									<tbody>
										<tr className='row'>
											<td>
												<FormControl
													className='input'
													fullWidth
													disabled={!!roomId}>
													<InputLabel
														id='room-name-label'
														htmlFor='outlined-adornment-room-name'
														size='small'>
														Name
													</InputLabel>
													<OutlinedInput
														id='outlined-adornment-room-name'
														type={"text"}
														label='Name'
														size='small'
														aria-describedby='room-name-label'
														value={formik.values.name}
														onChange={formik.handleChange}
														onBlur={(event) => {
															async function check() {
																const val = event?.target?.value ?? "";
																if (val) {
																	const { data } = await checkRoomNameExists({
																		roomName: val,
																	});
																	const exists = data?.exists ?? false;
																	if (exists) {
																		formik.setFieldError(
																			"name",
																			"Name is already exists"
																		);
																	}
																}
															}
															check();
															formik.handleBlur(event);
														}}
														name='name'
													/>
													{formik.touched?.name && formik.errors.name ? (
														<span className='error'>{formik.errors.name}</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										<tr className='row'>
											<td>
												<FormControl className='input' fullWidth>
													<InputLabel
														id='room-description-label'
														htmlFor='outlined-adornment-room-description'
														size='small'>
														Description
													</InputLabel>
													<OutlinedInput
														id='outlined-adornment-room-description'
														type={"text"}
														label='Description'
														size='small'
														aria-describedby='room-description-label'
														value={formik.values.description}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='description'
													/>
													{formik.touched?.description &&
													formik.errors.description ? (
														<span className='error'>
															{formik.errors.description}
														</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										<tr className='row'>
											<td>
												<FormControl className='input' fullWidth>
													<InputLabel
														id='room-mqtt-label'
														htmlFor='outlined-adornment-room-mqtt'
														size='small'>
														MQTT Topic
													</InputLabel>
													<OutlinedInput
														id='outlined-adornment-room-mqtt'
														type={"text"}
														label='MQTT Topic'
														size='small'
														aria-describedby='room-mqtt-label'
														value={formik.values.mqttTopic}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='mqttTopic'
													/>
													{formik.touched?.mqttTopic &&
													formik.errors.mqttTopic ? (
														<span className='error'>
															{formik.errors.mqttTopic}
														</span>
													) : null}
												</FormControl>
											</td>
										</tr>
										{isEditMode ? (
											<tr className='row'>
												<td>
													<span>Active</span>
													<Checkbox
														{...{
															inputProps: { "aria-label": "Checkbox Active" },
														}}
														checked={formik.values.active}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='active'
													/>
												</td>
											</tr>
										) : null}
										<tr className='row'>
											<td>
												<FormControl
													fullWidth
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "left",
														alignItems: "center",
													}}>
													<FormLabel
														id='radio-buttons-group-label'
														style={{ marginRight: "20px" }}>
														Mode
													</FormLabel>
													<RadioGroup
														row
														aria-labelledby='radio-buttons-group-label'
														value={formik.values.mode}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														name='mode'>
														<FormControlLabel
															value='trial'
															control={<Radio />}
															label='Trial'
														/>
														<FormControlLabel
															value='live'
															control={<Radio />}
															label='Live'
														/>
													</RadioGroup>
													{formik.touched?.mode && formik.errors.mode ? (
														<span className='error'>{formik.errors.mode}</span>
													) : null}
												</FormControl>
											</td>
										</tr>
									</tbody>
								</table>

								<CardActions className='actions'>
									<Button
										variant='contained'
										className='btn'
										type='submit'
										startIcon={<KeyboardArrowUpIcon />}
										//onClick={() => dispatch(updateUser(user))}
									>
										SUBMIT
									</Button>
									<Button
										variant='contained'
										component={Link}
										to={"/rooms"}
										className='btn'
										startIcon={<CancelIcon />}>
										CANCEL
									</Button>
								</CardActions>
							</form>
						</CardContent>
					</Card>
				)}
			</Formik>
		</>
	);
}
