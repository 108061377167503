import { Box, Grid } from "@mui/material";

import Card from "./Card";
import { getDashboardState } from "../../../Store/dashboard";
import { useSelector } from "react-redux";

const cards = [
	{
		Room: 1,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Somerset Facility",
			type: "Standard",
			active: true,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
	{
		Room: 2,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Apple farm",
			type: "Standard",
			active: false,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
	{
		Room: 3,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Orange farm",
			type: "Standard",
			active: true,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
	{
		Room: 4,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Grapes farm",
			type: "Standard",
			active: true,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
	{
		Room: 5,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Banana farm",
			type: "Standard",
			active: false,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
	{
		Room: 6,
		Snapshot_date: "21.11.2023",
		Base_Recipe: {
			name: "Straberry farm",
			type: "Standard",
			active: true,
		},
		cost: {
			base: "21",
			modified: "20",
			saved: "1",
		},
	},
];
export default function Dashboard() {
	const slice = useSelector(getDashboardState);
	return (
		<Grid
			container
			alignItems='center'
			justifyContent='space-evenly'
			spacing={{ xs: 3, md: 5 }}>
			{/* <Title title={"Dashboard"} /> */}
			{Object.keys(slice?.dashboard?.data ?? {}).map((id) => {
				return (
					<Grid key={id} item xs={12}>
						<Box className='dashboard-card'>
							<Card card={id} />
						</Box>
					</Grid>
				);
			})}
		</Grid>
	);
}
