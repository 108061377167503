import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		updateUser: (state, action) => {
			state.user = action.payload;
		},
	},
});

export const getUserState = (state) => state.user;

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
