import React, { useEffect } from "react";

import { CardActionArea } from "@mui/material";
import Loader from "../MasterPage/Pages/Common/Loader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../services/API/appAPI";
import { useNavigate } from "react-router-dom";

export default function PositionedMenu() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const navigate = useNavigate();
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [logout, {}] = useLogoutMutation();
	// handleRequestErrors({ error, isError, navigate });

	async function _logout() {
		try {
			setIsLoading(true);
			const { error } = await logout();
			if (error == undefined) {
				navigate("/login");
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}

	if (isLoading) return <Loader />;

	return (
		<div>
			<CardActionArea className='person-wrapper' onClick={handleClick}>
				<PersonIcon
					fontSize='medium'
					className='menu-icon'
					id='demo-positioned-button'
					aria-controls={open ? "demo-positioned-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={open ? "true" : undefined}
				/>
			</CardActionArea>

			<Menu
				id='demo-positioned-menu'
				aria-labelledby='demo-positioned-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
				<MenuItem
					onClick={() => {
						handleClose();
						navigate("/profile");
					}}>
					Profile
				</MenuItem>
				<MenuItem
					onClick={async () => {
						handleClose();
						_logout();
					}}>
					Logout
				</MenuItem>
			</Menu>
		</div>
	);
}
