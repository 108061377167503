function setLocalStorageItem(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

function getLocalStorageItem(key) {
	const item = localStorage.getItem(key);
	return item ? JSON.parse(item) : null;
}

function removeLocalStorageItem(key) {
	localStorage.removeItem(key);
}

function clearLocalStorage() {
	localStorage.clear();
}

const keys = {
	loginUserInfo: "userinfo",
};

export function saveUserInfo(user) {
	setLocalStorageItem(keys.loginUserInfo, user);
}

export function getUserInfo() {
	return getLocalStorageItem(keys.loginUserInfo);
}
