import { useEffect } from "react";
import { useNavigate } from "react-router";
// import { useLogoutMutation } from "../../../services/API/appAPI";

//It will execute for every authorized route changes

// Function to check if a cookie exists
export function checkCookie(cookieName) {
	var cookies = document.cookie.split(";");
	console.log(cookies);
	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i].trim();
		// Check if this cookie is the one we are looking for
		if (cookie.indexOf(cookieName) === 0) {
			return true; // Cookie exists
		}
	}
	return false; // Cookie does not exist
}

// export function deleteCookie(name) {
// 	document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
// }

// export function logout() {
// 	var cookieName = "connect.sid";
// 	deleteCookie(cookieName);
// 	const [_logout, {}] = useLogoutMutation();
// 	_logout();
// }
// function isValidDate(d) {
// 	return d instanceof Date && !isNaN(d);
// }

// function getCookieExpiration(cookieName) {
// 	// Split document.cookie into individual cookies
// 	const cookies = document.cookie.split(";");

// 	// Loop through each cookie to find the one with the specified name
// 	for (let i = 0; i < cookies.length; i++) {
// 		const cookie = cookies[i].trim();

// 		// Check if this cookie is the one we're looking for
// 		if (cookie.startsWith(cookieName + "=")) {
// 			// Get the cookie value and split it into its components
// 			const cookieValue = cookie.substring(cookieName.length + 1);
// 			const cookieComponents = cookieValue.split(";");

// 			// Loop through each component to find the expiration date
// 			for (let j = 0; j < cookieComponents.length; j++) {
// 				const component = cookieComponents[j].trim();

// 				// Check if this component contains the expiration date
// 				if (component.startsWith("expires=")) {
// 					// Extract the expiration date and return it
// 					const expirationDateString = component.substring("expires=".length);
// 					return new Date(expirationDateString);
// 				}
// 			}
// 		}
// 	}

// 	// Return null if the cookie doesn't exist or doesn't have an expiration date
// 	return null;
// }
// // Function to check if a cookie has expired
// export function isCookieExpired(cookieName) {
// 	var cookieValue = document.cookie.replace(
// 		new RegExp("(?:(?:^|.*;\\s*)" + cookieName + "\\s*\\=\\s*([^;]*).*$)|^.*$"),
// 		"$1"
// 	);
// 	if (cookieValue) {
// 		var cookieDate = new Date(cookieValue);
// 		var currentDate = new Date();
// 		// if (cookieDate < currentDate || !isValidDate(cookieDate)) {
// 		// 	deleteCookie(cookieName);
// 		// }
// 		// Compare cookie expiry date with current date
// 		return cookieDate < currentDate;
// 	}
// 	return true; // Cookie does not exist or does not have a valid date format
// }

function AuthorizedRoute({ children }) {
	const navigate = useNavigate();

	useEffect(() => {
		var cookieName = "connect.sid";
		if (checkCookie(cookieName)) {
			console.log("The cookie exists.");
		} else {
			console.log("The cookie does not exist.");
			navigate("/login");
		}
	});

	return children;
}

export default AuthorizedRoute;
