import * as React from "react";

import { Divider, Stack } from "@mui/material";
import { deleteModal, questionModal, successModal } from "../Common/Modal";
import {
	useDeleteRecipesRoomsAssociationMutation,
	useLazyGetActiveRecipesOfRoomQuery,
	useLazyGetRecipesRoomsAssociationQuery,
	useRecipeRoomAssociateMutation,
	useUpdateRecipesRoomsAssociationMutation,
} from "../../../services/API/appAPI";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import Load from "../Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePagination from "../Common/usePagination";

/**
 * TODO
 * 	- add [apply to niagara] btn, display if the recipe is in live and display pop up when clicked
 * 	- recipe confirmation pop up
 */

export default function FilteredRecipeLinkTable({ hideDelete = false, load }) {
	const [
		getAssociations,
		{
			currentData: associations,
			isLoading: loadAssociations,
			isFetching: fetchAssociations,
			// isUninitialized,
		},
	] = useLazyGetRecipesRoomsAssociationQuery();
	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);

	const [deleteAssociation, { isLoading: loadDelete }] =
		useDeleteRecipesRoomsAssociationMutation();

	const [updateAssociation, { isLoading: loadUpdate }] =
		useUpdateRecipesRoomsAssociationMutation();

	const [
		getActiveRecipesOfRoom,
		{
			isLoading: loadGetActiveRecipesOfRoom,
			isFetching: fetchGetActiveRecipesOfRoom,
		},
	] = useLazyGetActiveRecipesOfRoomQuery();

	const [recipeRoomAssociate, { isLoading: loadAssociate }] =
		useRecipeRoomAssociateMutation();

	React.useEffect(() => {
		async function fillTable() {
			const { data } = await getAssociations({
				count: entriesPerPage,
				page: currentPage,
			});
			const { totalCount = 0 } = data ?? {};
			setTotal(Number(totalCount));
		}
		fillTable();
	}, [currentPage, entriesPerPage, load]);

	async function onDelete(row) {
		const { isConfirmed } = await deleteModal({
			title: `Delete link recipe: ${row?.recipe?.name} - room: ${row?.room?.name}`,
		});
		if (isConfirmed && row?.recipe?.id && row?.room?.id) {
			const { error, data } = await deleteAssociation({
				recipeId: row?.recipe?.id,
				roomId: row?.room?.id,
			});
			if (error == undefined) {
				successModal({
					message: `Link recipe: ${row?.recipe?.name} - room: ${row?.room?.name} deleted successfully!`,
				}).then(() => {
					getAssociations({
						count: entriesPerPage,
						page: currentPage,
					});
				});
			}
		}
	}
	async function onActivate(row) {
		const { isConfirmed } = await questionModal({
			message: [
				`Are you sure to activate link between recipe to room?`,
				`Recipe: ${row?.recipe?.name}`,
				`Room: ${row?.room?.name}`,
			],
		});
		if (isConfirmed && row?.room && row?.recipe) {
			const { error, data } = await recipeRoomAssociate({
				roomName: row?.room?.name,
				roomId: row?.room?.id,
				processingTime: row?.recipe?.processingTime,
				pushTime: row?.recipe?.pushTime,
				active: true,
				recipeId: row?.recipe?.id,
			});
			if (error == undefined) {
				successModal({
					message: `${
						data?.message ?? "Recipe-Room Link updated successfully!"
					}`,
				}).then(() => {
					getAssociations({
						count: entriesPerPage,
						page: currentPage,
					});
				});
			}
		}
	}

	function getActionButtons(row) {
		const { showActivate = false, showDelete = false } = row ?? {};
		return (
			<Stack
				direction={"row"}
				spacing={0.5}
				sx={{ paddingLeft: "12px !important;" }}>
				<DeleteIcon
					className={`delete-icon ${showDelete ? "" : "disabled"}`}
					onClick={() => {
						onDelete(row);
					}}
				/>
				<Divider orientation='vertical' flexItem />
				<CheckIcon
					className={showActivate ? "edit-icon" : "edit-icon disabled"}
					onClick={() => onActivate(row)}
				/>
			</Stack>
		);
	}

	function formatTime(time) {
		const hours = Math.round(Number(time) / 100)
			.toString()
			.padStart(2, "0");
		const minutes = (Number(time) % 100).toString().padStart(2, "0");
		if (!time) return "";
		return hours + ":" + minutes;
	}

	if (loadAssociations || fetchAssociations) return <Load />;

	return (
		<>
			<Load
				open={
					loadDelete ||
					loadUpdate ||
					loadGetActiveRecipesOfRoom ||
					fetchGetActiveRecipesOfRoom ||
					loadAssociate
				}
			/>
			<TableContainer className='card data-table'>
				{/* <Box
					sx={{
						margin: "auto",
						marginTop: "1rem",
						width: "75%",
					}}>
					<Box className='form-body'>
						<Grid
							container
							direction={"row"}
							justifyContent={"center"}
							spacing={2}>
							<Grid item xs>
								<FormControl
									size='small'
									fullWidth
									sx={{ marginBottom: "1rem" }}>
									<InputLabel id='room-filter-id'>Room</InputLabel>
									<Select labelId='room-filter-id' label='Location'>
										<MenuItem value={10}>Ten</MenuItem>
										<MenuItem value={20}>Twenty</MenuItem>
										<MenuItem value={30}>Thirty</MenuItem>
									</Select>
								</FormControl>
								<FormControl size='small' fullWidth>
									<InputLabel id='recipe-type-filter-id'>
										Recipe Type
									</InputLabel>
									<Select
										labelId='recipe-type-filter-id'
										label='Recipe Processing Time'>
										<MenuItem value={10}>Ten</MenuItem>
										<MenuItem value={20}>Twenty</MenuItem>
										<MenuItem value={30}>Thirty</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs>
								<FormControl
									size='small'
									fullWidth
									sx={{ marginBottom: "1rem" }}>
									<InputLabel id='recipe-name-filter'>Recipe Name</InputLabel>
									<Select labelId='recipe-name-filter' label='MQTT Push Time'>
										<MenuItem value={10}>Ten</MenuItem>
										<MenuItem value={20}>Twenty</MenuItem>
										<MenuItem value={30}>Thirty</MenuItem>
									</Select>
								</FormControl>
								<div style={{ margin: 0 }}>
									<span>Active</span>
									<Checkbox
										{...{
											inputProps: { "aria-label": "Checkbox Active" },
										}}
									/>
								</div>
							</Grid>
						</Grid>
					</Box>
					<Stack
						direction={"row"}
						justifyContent={"center"}
						spacing={2}
						sx={{ marginBottom: "1rem" }}>
						<Button
							variant='contained'
							className='btn'
							endIcon={<ArrowForwardIosIcon />}
							//onClick={() => dispatch(updateUser(user))}
						>
							Reset Filter
						</Button>
						<Button
							variant='contained'
							className='btn'
							endIcon={<ArrowForwardIosIcon />}
							//onClick={() => dispatch(updateUser(user))}
						>
							Apply Filter
						</Button>
					</Stack>
				</Box> */}
				<Table sx={{ minWidth: 650 }} aria-label='roles table'>
					<TableHead>
						<TableRow>
							{hideDelete ? null : <TableCell>Action</TableCell>}
							<TableCell>Room</TableCell>
							<TableCell>Recipe Name</TableCell>
							<TableCell sx={{ width: "55px" }}>Active</TableCell>
							<TableCell sx={{ width: "55px" }}>Type</TableCell>
							<TableCell sx={{ width: "150px", padding: "0.2rem !important" }}>
								Recipe Processing Time
							</TableCell>
							<TableCell sx={{ width: "100px", padding: "0.2rem !important" }}>
								MQTT Push Time
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{associations?.recipes?.map((association, index) => (
							<TableRow key={association?.recipe?.id} className='row'>
								{hideDelete ? null : (
									<TableCell
										sx={{ width: "100px", padding: "0.2rem !important" }}>
										{getActionButtons(association)}
									</TableCell>
								)}
								<TableCell>{association?.room?.name ?? ""}</TableCell>
								<TableCell>{association?.recipe?.name ?? ""}</TableCell>
								<TableCell sx={{ width: "55px" }}>
									{association?.recipe?.active ? "YES" : "NO"}
								</TableCell>
								<TableCell sx={{ width: "55px" }}>
									{association?.recipe?.type ?? ""}
								</TableCell>
								<TableCell
									sx={{ textAlign: "center", paddingRight: "45px !important" }}>
									{formatTime(association?.recipe?.processingTime ?? "")}
								</TableCell>
								<TableCell
									sx={{ textAlign: "center", paddingRight: "60px !important" }}>
									{formatTime(association?.recipe?.pushTime ?? "")}
								</TableCell>
							</TableRow>
						))}
						{associations?.recipes?.length == 0 ? (
							<div style={{ padding: "1rem" }}>No data Found</div>
						) : null}
					</TableBody>
				</Table>
				{associations?.recipes?.length > 0 && render()}
			</TableContainer>
		</>
	);
}
