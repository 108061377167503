import { Box, Stack } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

import BackpackIcon from "@mui/icons-material/Backpack";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LOGOIcon from "./Icons/LogIcon";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TagIcon from "@mui/icons-material/Tag";
import { useState } from "react";

const sideBarOptions = [
	{
		name: "DASHBOARD",
		icon: <DashboardOutlinedIcon className='menu-icon' />,
		path: "/dashboard",
	},
	{
		name: "RECIPES",
		icon: <TagIcon className='menu-icon' />,
		path: "/recipes",
	},
	{
		name: "ROOMS",
		icon: <HomeOutlinedIcon className='menu-icon' />,
		path: "/rooms",
	},
	{
		name: "USERS",
		icon: <PeopleOutlinedIcon className='menu-icon' />,
		path: "/users",
	},
	{
		name: "SETTINGS",
		icon: <SettingsOutlinedIcon className='menu-icon' />,
		path: "/settings",
	},
	{
		name: "ROLES",
		icon: <HowToRegIcon className='menu-icon' />,
		path: "/roles",
	},
	{
		name: "ACTIVITIES",
		icon: <BackpackIcon className='menu-icon' />,
		path: "/activity",
	},
];

/**
 * Recipe File Icon Pending
 */

export default function SideMenu() {
	const [openMenu, setOpenMenu] = useState(false);
	function closeMenu() {
		setOpenMenu(false);
	}
	return (
		<div className='side-bar'>
			<Box className={openMenu ? "side-bar-list open" : "side-bar-list"}>
				<MenuList dense>
					<Link to='dashboard'>
						<MenuItem className='menu-item'>
							<LOGOIcon />
						</MenuItem>
					</Link>
					<hr className='horizontal-divider' />
					{sideBarOptions.map(({ name, icon, path }) => {
						return (
							<NavLink
								key={name}
								to={path}
								onClick={closeMenu}
								className={({ isActive, isPending }) =>
									isActive ? "active" : isPending ? "pending" : ""
								}>
								<MenuItem className='menu-item'>
									<ListItemIcon>{icon}</ListItemIcon>
									<div aria-label={name}> {name}</div>
								</MenuItem>
							</NavLink>
						);
					})}
				</MenuList>
			</Box>
		</div>
	);
}
